import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'; // Composant pour gérer le formulaire
import './Paiement.css'; // Importation du style

// Chargez la clé publique Stripe
const stripePromise = loadStripe('pk_test_51QNbZsJREVtEo3fbrVWSnRw8bXBhKJyBJunkQGBhbgoxKDF9riwM5xhprl65w6e4Euiw6Sc5y4sweJ9lNBhzpulH00YLgEl72w');

const Paiement = () => {
  const location = useLocation();
  const { total } = location.state || {}; // Récupérer le total depuis ResumeCommande.js
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    // Appel à votre backend pour obtenir le clientSecret
    fetch('https://skaneat-app.vercel.app/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount: total }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => console.error('Erreur lors de la récupération du clientSecret :', error));
  }, [total]);

  const appearance = { theme: 'stripe' };
  const options = { clientSecret, appearance };

  return (
    <div className="payment-container">
      <h1>Page de Paiement</h1>
      <h3>Total à payer : {total.toFixed(2)} €</h3>
      {clientSecret ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm total={total} clientSecret={clientSecret} /> {/* Passer clientSecret comme prop */}
        </Elements>
      ) : (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
};

export default Paiement;
