import React, { useState } from 'react';
import { collection, getDocs, doc, getDoc, updateDoc, addDoc, Timestamp, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import './FirestoreViewer.css'; // Fichier CSS pour le style

const FirestoreViewer = () => {
  const [collections] = useState([
    'clients',
    'total_commande',
    'menu',
    'satisfaction',
    'identifications',
    'horaires',
    'total_client',
  ]);
  const [selectedCollection, setSelectedCollection] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDocument, setEditedDocument] = useState(null);
  const [isAdding, setIsAdding] = useState(false);

  // Récupérer les documents d'une collection
  const fetchCollectionData = async (collectionName) => {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const docs = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setDocuments(docs);
      setSelectedCollection(collectionName);
      setSelectedDocument(null);
    } catch (error) {
      console.error(`Erreur lors de la récupération des données pour ${collectionName}:`, error);
    }
  };

  // Récupérer les détails d'un document spécifique
  const fetchDocumentData = async (collectionName, documentId) => {
    try {
      const docRef = doc(db, collectionName, documentId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setSelectedDocument({ id: documentId, ...docSnap.data() });
      } else {
        console.error('Document introuvable.');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération du document:', error);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedDocument(JSON.parse(JSON.stringify(selectedDocument)));
  };

  const handleSave = async () => {
    try {
      const docRef = doc(db, selectedCollection, selectedDocument.id);
      await updateDoc(docRef, editedDocument);
      setSelectedDocument(editedDocument);
      setIsEditing(false);
      alert('Modifications enregistrées avec succès!');
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error);
      alert('Erreur lors de la sauvegarde des modifications.');
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedDocument(null);
  };

  const handleJsonEdit = (jsonString) => {
    try {
      const parsed = JSON.parse(jsonString);
      setEditedDocument(parsed);
    } catch (error) {
      console.error('JSON invalide:', error);
      alert('Format JSON invalide');
    }
  };

  const handleAddSatisfaction = async () => {
    try {
      const [day, month, year] = document.getElementById('satisfactionDate').value.split('/');
      const date = new Date(year, month - 1, day);
      const feedback = document.getElementById('satisfactionFeedback').value;
      
      const docData = {
        feedback: feedback,
        application: 5,
        plats: 3,
        service: 1,
        date: Timestamp.fromDate(date)
      };

      await addDoc(collection(db, 'satisfaction'), docData);
      setIsAdding(false);
      fetchCollectionData('satisfaction');
      alert('Satisfaction ajoutée avec succès!');
    } catch (error) {
      console.error('Erreur lors de l\'ajout:', error);
      alert('Erreur lors de l\'ajout de la satisfaction.');
    }
  };

  const handleDeleteDocument = async (documentId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette satisfaction ?')) {
      try {
        await deleteDoc(doc(db, selectedCollection, documentId));
        fetchCollectionData(selectedCollection);
        alert('Document supprimé avec succès');
      } catch (error) {
        console.error('Erreur lors de la suppression:', error);
        alert('Erreur lors de la suppression du document');
      }
    }
  };

  const handleDocumentClick = (document) => {
    fetchDocumentData(selectedCollection, document.id);
  };

  return (
    <div className="firestore-viewer">
      <h1>Firestore Viewer</h1>
      <div className="firestore-panel">
        <div className="firestore-collections">
          <h2>Collections</h2>
          <ul>
            {collections.map((collectionName) => (
              <li key={collectionName} onClick={() => fetchCollectionData(collectionName)}>
                {collectionName}
              </li>
            ))}
          </ul>
        </div>

        <div className="firestore-documents">
          <h2>Documents de la collection "{selectedCollection}"</h2>
          {selectedCollection === 'satisfaction' && (
            <button onClick={() => setIsAdding(true)}>+ Ajouter une satisfaction</button>
          )}
          {isAdding && selectedCollection === 'satisfaction' && (
            <div className="add-satisfaction-form">
              <input
                type="text"
                id="satisfactionDate"
                placeholder="JJ/MM/AAAA"
              />
              <textarea
                id="satisfactionFeedback"
                placeholder="Feedback"
                rows="3"
              ></textarea>
              <button onClick={handleAddSatisfaction}>Enregistrer</button>
              <button onClick={() => setIsAdding(false)}>Annuler</button>
            </div>
          )}
          {documents.length > 0 ? (
            <ul>
              {documents.map((doc) => (
                <li key={doc.id} onClick={() => handleDocumentClick(doc)}>
                  {doc.id}
                  {selectedCollection === 'satisfaction' && (
                    <button 
                      className="delete-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteDocument(doc.id);
                      }}
                    >
                      Supprimer
                    </button>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p>Sélectionnez une collection pour afficher ses documents.</p>
          )}
        </div>

        <div className="firestore-document-details">
          <h2>Détails du document</h2>
          {selectedDocument ? (
            <>
              {isEditing ? (
                <div>
                  <textarea
                    style={{ width: '100%', minHeight: '300px', fontFamily: 'monospace' }}
                    value={JSON.stringify(editedDocument, null, 2)}
                    onChange={(e) => {
                      try {
                        handleJsonEdit(e.target.value);
                      } catch (error) {
                        console.log('En cours d\'édition...');
                      }
                    }}
                  />
                  <div className="edit-buttons">
                    <button onClick={handleSave}>Enregistrer</button>
                    <button onClick={handleCancel}>Annuler</button>
                  </div>
                </div>
              ) : (
                <>
                  <pre>{JSON.stringify(selectedDocument, null, 2)}</pre>
                  <button onClick={handleEdit}>Modifier</button>
                </>
              )}
            </>
          ) : (
            <p>Sélectionnez un document pour afficher ses détails.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FirestoreViewer;
