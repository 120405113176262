import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Importez Firebase Storage

// Votre configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyA444uH2z7l3g04HDzG6qJk_uOdnVvJgSY",
  authDomain: "skaneat-corp-app.firebaseapp.com",
  projectId: "skaneat-corp-app",
  storageBucket: "skaneat-corp-app.appspot.com",
  messagingSenderId: "690703662138",
  appId: "1:690703662138:web:78a23588f50ab533794903",
  measurementId: "G-N40ELFWRFE"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialisez Firebase Storage

export { db, auth, storage };