import React, { useState, useEffect } from 'react';
import { db } from '../../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import './SchedulePopup.css';

const dayMapping = {
  'Monday': 'Lundi',
  'Tuesday': 'Mardi',
  'Wednesday': 'Mercredi',
  'Thursday': 'Jeudi',
  'Friday': 'Vendredi',
  'Saturday': 'Samedi',
  'Sunday': 'Dimanche'
};

const SchedulePopup = ({ employee, onClose, existingSchedule, updateSchedule, selectedDay }) => {
  const [shifts, setShifts] = useState([{ start: '', end: '' }]);
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  useEffect(() => {
    // Sauvegarder la position de défilement actuelle
    const scrollPosition = window.scrollY;
    document.body.classList.add('popup-open');
    // Appliquer la position de défilement comme margin-top
    document.body.style.top = `-${scrollPosition}px`;
    
    return () => {
      // Restaurer la position de défilement
      document.body.classList.remove('popup-open');
      document.body.style.top = '';
      window.scrollTo(0, scrollPosition);
    };
  }, []);

  useEffect(() => {
    if (existingSchedule) {
      const currentSchedule = existingSchedule.schedule[selectedDay];
      if (currentSchedule && currentSchedule !== 'Ne travaille pas') {
        // Convertir le format existant en tableau de shifts
        const existingShifts = currentSchedule.split(' / ').map(shift => {
          const [start, end] = shift.split(' - ');
          return { start, end };
        });
        setShifts(existingShifts);
      } else {
        setShifts([{ start: '', end: '' }]);
      }
    }
  }, [existingSchedule, selectedDay]);

  const handleShiftChange = (index, field, value) => {
    const newShifts = [...shifts];
    newShifts[index][field] = value;
    setShifts(newShifts);
  };

  const addShift = () => {
    setShifts([...shifts, { start: '', end: '' }]);
  };

  const removeShift = (index) => {
    if (shifts.length > 1) {
      const newShifts = shifts.filter((_, i) => i !== index);
      setShifts(newShifts);
    }
  };

  const handleSubmit = async (e, applyToAll = false) => {
    e.preventDefault();

    // Vérifier si tous les champs sont vides
    const isEmpty = shifts.every(shift => !shift.start && !shift.end);
    const scheduleValue = isEmpty ? 'Ne travaille pas' : 
      shifts.map(shift => `${shift.start} - ${shift.end}`).join(' / ');

    try {
      const scheduleRef = doc(db, 'horaires', employee.id);
      let updatedSchedule;

      if (applyToAll) {
        updatedSchedule = daysOfWeek.reduce((acc, day) => ({
          ...acc,
          [day]: scheduleValue
        }), {});
      } else {
        updatedSchedule = {
          ...existingSchedule?.schedule,
          [selectedDay]: scheduleValue
        };
      }

      await setDoc(scheduleRef, {
        employeeId: employee.id,
        firstName: employee.firstName,
        lastName: employee.lastName,
        role: employee.role,
        schedule: updatedSchedule,
      });

      updateSchedule(employee.id, {
        ...existingSchedule,
        schedule: updatedSchedule,
      });

      onClose();
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement des horaires:', error);
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h3>Modification des horaires pour {employee.firstName} {employee.lastName}</h3>
        <h4>{dayMapping[selectedDay]}</h4>
        
        {shifts.map((shift, index) => (
          <div key={index} className="shift-container">
            <div className="shift-inputs">
              <input
                type="time"
                value={shift.start}
                onChange={(e) => handleShiftChange(index, 'start', e.target.value)}
              />
              <span>à</span>
              <input
                type="time"
                value={shift.end}
                onChange={(e) => handleShiftChange(index, 'end', e.target.value)}
              />
              {shifts.length > 1 && (
                <button 
                  type="button" 
                  className="remove-shift"
                  onClick={() => removeShift(index)}
                >
                  ✕
                </button>
              )}
            </div>
          </div>
        ))}

        <button type="button" className="add-shift" onClick={addShift}>
          + Ajouter une coupure
        </button>

        <div className="button-group">
          <button type="button" onClick={(e) => handleSubmit(e, false)}>
            Enregistrer
          </button>
          <button type="button" onClick={(e) => handleSubmit(e, true)}>
            Appliquer à tous
          </button>
        </div>
        <button type="button" className="close-button" onClick={onClose}>
          Fermer
        </button>
      </div>
    </div>
  );
};

export default SchedulePopup;
