import React, { useState } from 'react';
import { auth } from '../firebase'; // Assurez-vous d'importer Firebase Authentication configurée
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore'; // Importer Firestore pour récupérer le rôle
import { db } from '../firebase'; // Assurez-vous d'importer correctement Firestore
import './auth.css';

const AuthLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Connexion avec Firebase Authentication
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Vérifier le rôle de l'utilisateur dans Firestore
      const userDoc = await getDoc(doc(db, 'identifications', user.uid));

      if (userDoc.exists()) {
        const userRole = userDoc.data().role;

        // Redirection en fonction du rôle
        if (userRole === 'admin') {
          navigate('/admin-dashboard'); // Interface admin
        } else if (userRole === 'server') {
          navigate('/server-interface'); // Interface serveur
        } else if (userRole === 'cook') {
          navigate('/cook-interface'); // Interface serveur
        } else if (userRole === 'bar') {
          navigate('/bar-interface'); // Interface serveur
        } else {
          setError('Votre rôle ne permet pas d\'accéder à cette application.');
        }
      } else {
        setError('Utilisateur non trouvé dans la base de données.');
      }
    } catch (err) {
      setError('Identifiants incorrects ou problème lors de la connexion.');
    }
  };

  return (
    <div className="login-container">
      <h2>Connexion</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Email :</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Entrez votre email"
          />
        </div>
        <div className="form-group">
          <label>Mot de passe :</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Entrez votre mot de passe"
          />
        </div>
        <button type="submit" className="login-button">Se connecter</button>
      </form>
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '10px 0',
          fontSize: '0.9em',
          color: '#555',
        }}
      >
        Cette application est développée par{' '}
        <a
          href="https://skaneat.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          SkanEat
        </a>
        .
      </div>
    </div>
  );
};

export default AuthLogin;
