import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, query, onSnapshot } from 'firebase/firestore'; // Retirer getDocs ici
import { useNavigate } from 'react-router-dom';
import './Resumecommande.css';

const ResumeCommande = () => {
  const [articles, setArticles] = useState([]); // État pour les articles regroupés
  const [total, setTotal] = useState(0); // Total général
  const [statut, setStatut] = useState(''); // État pour le statut de la commande
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false); 
  const [tableLabel, setTableLabel] = useState('');
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    if (userInfo.table === 'a_emporter') {
      setTableLabel('À Emporter');
    } else {
      setTableLabel(`Numéro de Table : ${userInfo.table}`);
    }
  }, [userInfo.table]);  

  useEffect(() => {
    // Fonction pour récupérer les commandes
    const fetchCommandes = async () => {
      try {
        const commandeCollection = collection(db, `clients/${userInfo.id}/commande`);
        const q = query(commandeCollection);

        // Utiliser onSnapshot pour écouter les changements en temps réel
        onSnapshot(q, (querySnapshot) => {
          const allCommandes = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            allCommandes.push(...data.items);
            setStatut(data.statut); // Récupérer le statut de la commande
          });

          const groupedArticles = allCommandes.reduce((acc, item) => {
            const existingItemIndex = acc.findIndex(
              (existingItem) => existingItem.nom === item.nom && existingItem.cuisson === item.cuisson
            );

            if (existingItemIndex !== -1) {
              acc[existingItemIndex].quantity += item.quantity;
            } else {
              acc.push({ ...item });
            }

            return acc;
          }, []);

          setArticles(groupedArticles);

          // Calculer le total général
          const totalAmount = groupedArticles.reduce((acc, item) => acc + item.prix * item.quantity, 0);
          setTotal(totalAmount);
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des commandes: ", error);
      }
    };

    fetchCommandes();
  }, [userInfo.id]);

  useEffect(() => {
    const fetchCommandes = async () => {
      try {
        const commandeCollection = collection(db, `clients/${userInfo.id}/commande`);
        const q = query(commandeCollection);
  
        onSnapshot(q, (querySnapshot) => {
          let latestCommande = null;
          querySnapshot.forEach(doc => {
            const data = doc.data();
            const commandeDate = data.date.toDate();
  
            if (!latestCommande || commandeDate > latestCommande.date) {
              latestCommande = { ...data, date: commandeDate };
            }
          });
  
          if (latestCommande) {
            setStatut(latestCommande.statut);
          }
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des commandes: ", error);
      }
    };
  
    fetchCommandes();
  }, [userInfo.id]);

  const handleFinishAndPay = () => {
    setShowPopup(true); // Afficher la popup
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Fermer la popup
  };

  const handleContinueOrder = () => {
    navigate('/menu'); // Naviguer vers le menu pour ajouter plus d'articles
  };

  const handlePayMobile = () => {
    setShowPopup(false);
    navigate('/paiement', { state: { total } }); // Naviguer vers la page de paiement mobile
  };

  const handlePayAtCounter = () => {
    setShowPopup(false);
    navigate('/cashpaiement'); // Naviguer vers la page de paiement au comptoir
  };

  return (
    <div className="resume-container">
      <h1>Résumé de votre Commande</h1>
      <h2>Client: {userInfo.prenom}</h2>
      <h3>{tableLabel}</h3>
      <h3>Numéro de commande : {userInfo.idclient}</h3>
      <h3>Statut de la Commande: {statut}</h3>

      <h2>Détails de votre Commande</h2>
      <div className="command-details">
        {articles.length > 0 ? (
          articles.map((item, index) => (
            <p key={index}>
              • <strong>{item.nom}</strong> - Quantité: {item.quantity}
              {item.cuisson && ` - Cuisson: ${item.cuisson}`} 
              <br />
              Prix unitaire: {item.prix.toFixed(2)} €
              <br />
              Prix total: {(item.prix * item.quantity).toFixed(2)} €
            </p>
          ))
        ) : (
          <p>Votre commande est vide.</p>
        )}
      </div>
      <h3 className="command-total">Total général: {total.toFixed(2)} €</h3>

      <div className="buttons-container">
        <button onClick={handleContinueOrder}>Continuer la commande</button>
        <button className="secondary" onClick={handleFinishAndPay}>
          Terminer et payer
        </button>
      </div>
      {showPopup && (
        <div className="payment-popup">
          <div className="popup-content-paiement">
            <h3>Choisissez votre méthode de paiement</h3>
            <button onClick={handlePayMobile}>Payer sur mobile</button>
            <button onClick={handlePayAtCounter}>Payer au comptoir</button>
            <button onClick={handleClosePopup}>Annuler</button>
          </div>
        </div>
      )}
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '10px 0',
          fontSize: '0.9em',
          color: '#555',
        }}
      >
        Cette application est développée par{' '}
        <a
          href="https://skaneat.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          SkanEat
        </a>
        .
      </div>
    </div>
  );
};

export default ResumeCommande;
