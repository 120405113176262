import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import './TakeOrder.css';

const TakeOrder = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('Préparation de la commande...');

  React.useEffect(() => {
    try {
      const user = auth.currentUser;
      console.log('Current user:', user);

      if (user) {
        const userInfo = {
          prenom: user.email.split('@')[0],
          table: '0',
          email: user.email,
          idclient: Math.floor(1000 + Math.random() * 9000)
        };

        localStorage.setItem('userInfo', JSON.stringify(userInfo));

        setMessage('Redirection vers le menu...');
        setTimeout(() => {
          navigate('/menu');
        }, 2000);
      } else {
        console.error('Aucun utilisateur connecté');
        navigate('/login');
      }
    } catch (error) {
      console.error('Erreur lors de la redirection:', error);
    }
  }, [navigate]);

  return (
    <div className="take-order-container">
      <h1 className="take-order-message">{message}</h1>
      <div className="loading-spinner" />
    </div>
  );
};

export default TakeOrder; 