import React, { useState } from 'react';
import axios from 'axios';
import { db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import './admin.css';

const AddDishForm = ({ menuId }) => {
  const [nom, setNom] = useState('');
  const [prix, setPrix] = useState('');
  const [description, setDescription] = useState('');
  const [photo, setPhoto] = useState(null);
  const [categorie, setCategorie] = useState('');
  const [erreur, setErreur] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [modifier, setModifier] = useState('none');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nom || !prix || !description || !categorie) {
      setErreur('Tous les champs sont requis, sauf la photo.');
      return;
    }

    setErreur('');
    setUploading(true);

    try {
      let photoURL = '';

      if (photo) {
        // Étape 1 : Télécharger la photo sur Cloudinary
        const formData = new FormData();
        formData.append('file', photo);
        formData.append('upload_preset', 'menu_photos'); // Ajoutez ici votre Upload Preset
        formData.append('cloud_name', 'dyuyrwww1'); // Ajoutez ici votre Cloud Name

        const response = await axios.post(
          `https://api.cloudinary.com/v1_1/dyuyrwww1/image/upload`,
          formData
        );

        photoURL = response.data.secure_url; // URL sécurisée de l'image
      }

      // Étape 2 : Sauvegarder les données dans Firestore
      const idNom = nom.replace(/\s+/g, '_').toLowerCase();
      await setDoc(doc(db, `menu/${menuId}/${categorie}`, idNom), {
        nom,
        prix: parseFloat(prix),
        description,
        photo: photoURL || null,
        categorie,
        modifier: modifier === 'none' ? null : modifier,
        actif: true,
        createdAt: new Date(),
      });

      triggerPopup();
      resetForm();
    } catch (error) {
      console.error('Erreur lors de l\'ajout du plat :', error.message);
      setErreur('Erreur lors de l\'ajout du plat. Vérifiez votre connexion ou vos paramètres Cloudinary.');
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setNom('');
    setPrix('');
    setDescription('');
    setPhoto(null);
    setCategorie('');
    setModifier('none');
  };

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  return (
    <div className="add-dish form-container">
      <h2>Ajouter un nouveau plat</h2>
      {erreur && <p style={{ color: 'red' }}>{erreur}</p>}
      <form onSubmit={handleSubmit}>
        <div className="dish-flex">
          <div>
            <label>Nom du plat :</label>
            <input
              type="text"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Prix (€) :</label>
            <input
              type="number"
              value={prix}
              onChange={(e) => setPrix(e.target.value)}
              required
            />
          </div>
        </div>
        <div>
          <label>Description :</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="dish-flex">
          <div>
            <label>Photo :</label>
            <input
              type="file"
              id="photo-upload"
              accept="image/*"
              onChange={(e) => setPhoto(e.target.files[0])}
            />
            <label htmlFor="photo-upload">
              {photo ? photo.name : "Sélectionner une photo"}
            </label>
          </div>
          <div>
            <label>Catégorie :</label>
            <select
              value={categorie}
              onChange={(e) => setCategorie(e.target.value)}
              required
            >
              <option value="">Sélectionnez une catégorie</option>
              <option value="entrees">Entrée</option>
              <option value="plats">Plat</option>
              <option value="desserts">Dessert</option>
              <option value="boissons">Boisson</option>
            </select>
          </div>
        </div>
        <div>
          <label>Supplément disponible :</label>
          <select
            value={modifier}
            onChange={(e) => setModifier(e.target.value)}
          >
            <option value="none">Aucun supplément disponible</option>
            <option value="sauce">Choix de sauce</option>
            <option value="cuisson">Choix de cuisson</option>
            <option value="accompagnement">Choix d'accompagnement</option>
            <option value="supplement">Supplément payant</option>
          </select>
        </div>
        <button type="submit" disabled={uploading}>
          {uploading ? 'Téléchargement...' : 'Ajouter le plat'}
        </button>
      </form>

      {showPopup && (
        <div className="popup-confirmation fade-in">
          Plat ajouté avec succès !
        </div>
      )}
    </div>
  );
};

export default AddDishForm;
