import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../../firebase';
import { doc, setDoc, collection, deleteDoc, onSnapshot } from 'firebase/firestore';
import './admin.css';

const AddCookForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [cooks, setCooks] = useState([]);
  const [selectedCook, setSelectedCook] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const cooksCollection = collection(db, 'identifications');
    
    const unsubscribe = onSnapshot(cooksCollection, (snapshot) => {
      const cookList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCooks(cookList);
    }, (error) => {
      console.error("Erreur lors de la récupération des cuisiniers : ", error);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (selectedCook) {
        // Mise à jour uniquement du nom et du prénom
        const cookDoc = doc(db, 'identifications', selectedCook.id);
        await setDoc(cookDoc, {
          ...selectedCook, // Conserver les données existantes
          firstName,
          lastName,
        });

        setSuccess('Nom et prénom mis à jour avec succès !');
      } else {
        // Ajouter un nouveau cuisinier
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await setDoc(doc(db, 'identifications', user.uid), {
          email: user.email,
          firstName,
          lastName,
          role: 'cook',
          createdAt: new Date(),
        });
        setSuccess('Cuisinier ajouté avec succès !');
      }

      // Réinitialiser les champs
      setEmail('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setSelectedCook(null);
    } catch (error) {
      setError('Erreur lors de l\'ajout ou de la mise à jour du cuisinier : ' + error.message);
    }
  };

  const handleEdit = (cook) => {
    setFirstName(cook.firstName);
    setLastName(cook.lastName);
    setSelectedCook(cook);
  };

  const handleDelete = async (serverId) => {
    try {
      // Appeler le backend pour supprimer l'utilisateur dans Firebase Authentication
      const response = await fetch('https://skaneat-app.vercel.app/delete-user', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: serverId }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erreur lors de la suppression de l’utilisateur.');
      }
  
      // Supprimer l'utilisateur dans Firestore Database
      await deleteDoc(doc(db, 'identifications', serverId));
      await deleteDoc(doc(db, 'horaires', serverId));
  
      setSuccess('Serveur supprimé avec succès !');
    } catch (error) {
      setError('Erreur lors de la suppression du cuisinier : ' + error.message);
    }
  };  

  return (
    <div className="server-form-container">
      <h2>{selectedCook ? 'Modifier un cuisinier' : 'Ajouter un cuisinier'}</h2>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nom :</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Prénom :</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>

        {!selectedCook && (
          <>
            <div>
              <label>Email du cuisinier :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Mot de passe du cuisinier :</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </>
        )}

        <button type="submit">
          {selectedCook ? 'Mettre à jour le cuisinier' : 'Ajouter un cuisinier'}
        </button>
      </form>

      <div className="server-list-container">
        <h3>Cuisiniers Enregistrés :</h3>
        <ul>
          {cooks
            .filter((cook) => cook.role === 'cook') // Filtre les cuisiniers avec le rôle 'cook'
            .map((cook) => (
              <li key={cook.id}>
                {cook.firstName} {cook.lastName} - {cook.email}
                <div>
                  <button onClick={() => handleEdit(cook)}>Modifier</button>
                  <button onClick={() => handleDelete(cook.id)}>Supprimer</button>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default AddCookForm;
