// src/components/ProtectedMenu.js

import React from 'react';
import { Navigate } from 'react-router-dom';

// Composant pour protéger l'accès à la page Menu
const ProtectedMenu = ({ children }) => {
  const userInfo = localStorage.getItem('userInfo');

  // Si les coordonnées de l'utilisateur ne sont pas dans le localStorage, rediriger vers le formulaire
  if (!userInfo) {
    return <Navigate to="/?error=access_denied" />;
  }

  // Si les coordonnées existent, permettre l'accès au menu
  return children;
};

export default ProtectedMenu;
