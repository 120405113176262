import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import './view.css';

const ViewMenuItems = ({ menuId }) => {
  const [categorie, setCategorie] = useState('');
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [editingField, setEditingField] = useState(null);

  const fetchItems = useCallback(async (cat) => {
    try {
      const itemsCollection = collection(db, `menu/${menuId}/${cat}`);
      const itemSnapshot = await getDocs(itemsCollection);
      const itemList = itemSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setItems(itemList);
      setError('');
    } catch (error) {
      console.error('Erreur lors de la récupération des éléments:', error);
      setError('Erreur lors de la récupération des éléments.');
    }
  }, [menuId]);

  const handleCategoryChange = useCallback((cat) => {
    setCategorie(cat);
    fetchItems(cat);
  }, [fetchItems]);

  useEffect(() => {
    handleCategoryChange('entrees');
  }, [handleCategoryChange]);

  const handleDelete = async (item) => {
    try {
      await deleteDoc(doc(db, `menu/${menuId}/${categorie}`, item.id));
      setConfirmation('Élément supprimé avec succès !');
      triggerPopup();
      fetchItems(categorie);
    } catch (error) {
      console.error('Erreur lors de la suppression:', error);
    }
  };

  const handleToggleActive = async (item) => {
    try {
      const updatedState = !item.actif;
      const itemRef = doc(db, `menu/${menuId}/${categorie}`, item.id);
      await updateDoc(itemRef, { actif: updatedState });
      setConfirmation(`Élément ${updatedState ? 'activé' : 'désactivé'} avec succès !`);
      triggerPopup();
      fetchItems(categorie);
    } catch (error) {
      console.error('Erreur lors du changement d\'état:', error);
    }
  };

  const handleFieldUpdate = async (item, field, value) => {
    try {
      const itemRef = doc(db, `menu/${menuId}/${categorie}`, item.id);
      await updateDoc(itemRef, { [field]: value });
      setEditingField(null);
      setConfirmation('Champ mis à jour avec succès !');
      triggerPopup();
      fetchItems(categorie);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du champ:', error);
    }
  };

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 2000);
  };

  const handlePhotoChange = async (item, photoFile) => {
    try {
      const formData = new FormData();
      formData.append('file', photoFile);
      formData.append('upload_preset', 'menu_photos');
      formData.append('cloud_name', 'dyuyrwww1');

      const response = await axios.post(
        `https://api.cloudinary.com/v1_1/dyuyrwww1/image/upload`,
        formData
      );

      const photoURL = response.data.secure_url;
      await handleFieldUpdate(item, 'photo', photoURL);
    } catch (error) {
      console.error('Erreur lors du téléchargement de la photo:', error);
    }
  };

  return (
    <div>
      <h2>Visualiser les éléments du menu</h2>
      <div className="view-menu-items-buttons">
        <button onClick={() => handleCategoryChange('entrees')}>Entrées</button>
        <button onClick={() => handleCategoryChange('plats')}>Plats</button>
        <button onClick={() => handleCategoryChange('desserts')}>Desserts</button>
        <button onClick={() => handleCategoryChange('boissons')}>Boissons</button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <h3>{categorie.charAt(0).toUpperCase() + categorie.slice(1)}</h3>
      <ul className='view-menu-items-list'>
        {items.map((item) => (
          <li className='cart-view' key={item.id}>
            <span
              onClick={() => setEditingField(`nom-${item.id}`)}
              style={{ cursor: 'pointer', textDecoration: editingField === `nom-${item.id}` ? 'underline' : 'none' }}
            >
              {editingField === `nom-${item.id}` ? (
                <input
                  type="text"
                  defaultValue={item.nom}
                  onBlur={(e) => handleFieldUpdate(item, 'nom', e.target.value)}
                  autoFocus
                />
              ) : (
                <strong>{item.nom}</strong>
              )}
            </span>
            <br />
            <br />
            <span
              onClick={() => setEditingField(`prix-${item.id}`)}
              style={{ cursor: 'pointer', textDecoration: editingField === `prix-${item.id}` ? 'underline' : 'none' }}
            >
              {editingField === `prix-${item.id}` ? (
                <input
                  type="number"
                  defaultValue={item.prix}
                  onBlur={(e) => handleFieldUpdate(item, 'prix', parseFloat(e.target.value))}
                  autoFocus
                />
              ) : (
                `${item.prix}€`
              )}
            </span>
            <br />
            <span
              onClick={() => setEditingField(`description-${item.id}`)}
              style={{ cursor: 'pointer', textDecoration: editingField === `description-${item.id}` ? 'underline' : 'none' }}
            >
              {editingField === `description-${item.id}` ? (
                <textarea
                  defaultValue={item.description}
                  onBlur={(e) => handleFieldUpdate(item, 'description', e.target.value)}
                  autoFocus
                />
              ) : (
                item.description
              )}
            </span>
            <br />
            {item.photo ? (
              <div className="image-container">
                <img
                  src={item.photo}
                  alt={item.nom}
                  className="menu-item-photo"
                  onClick={() => document.getElementById(`fileInput-${item.id}`).click()}
                />
              </div>
            ) : (
              <button
                onClick={() => document.getElementById(`fileInput-${item.id}`).click()}
                className='add-photo-button'
              >
                Ajouter une photo
              </button>
            )}
            <input
              type="file"
              id={`fileInput-${item.id}`}
              style={{ display: 'none' }}
              onChange={(e) => handlePhotoChange(item, e.target.files[0])}
            />
            <br />
            <span>{item.actif ? 'Actif' : 'Inactif'}</span> <br />
            
            {item.modifier && (
              <>
                <span style={{ color: '#666', fontSize: '0.9em' }}>
                  {item.modifier === 'sauce' && 'Choix de sauce disponible'}
                  {item.modifier === 'cuisson' && 'Choix de cuisson disponible'}
                  {item.modifier === 'accompagnement' && 'Choix d\'accompagnement disponible'}
                  {item.modifier === 'supplement' && 'Suppléments disponibles'}
                </span>
                <br />
              </>
            )}
            
            <button className='view-supp-button' onClick={() => handleDelete(item)}>Supprimer</button>
            <button onClick={() => handleToggleActive(item)}>
              {item.actif ? 'Désactiver' : 'Activer'}
            </button>
          </li>
        ))}
      </ul>

      {showPopup && (
        <div className={`popup-confirmation ${showPopup ? 'fade-in' : 'fade-out'}`}>
          {confirmation}
        </div>
      )}
    </div>
  );
};

export default ViewMenuItems;