import React from 'react';
import './EmployeeCard.css'; // Ajoutez des styles spécifiques si nécessaire

const EmployeeCard = ({ employee, onClick, schedule }) => {
  // Fonction pour convertir le rôle en français
  const getRoleInFrench = (role) => {
    switch (role) {
      case 'server':
        return 'Serveur';
      case 'cook':
        return 'Cuisinier';
      case 'bar':
        return 'Barman';
      default:
        return role; // Retourne le rôle tel quel s'il n'est pas reconnu
    }
  };

  // Mapping des jours en anglais vers le français
  const dayMapping = {
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche"
  };

  // L'ordre des jours de la semaine en français
  const daysOfWeek = [
    "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
  ];

  // Fonction pour trier et afficher les horaires des jours de la semaine dans l'ordre
  const displaySchedule = schedule
    ? daysOfWeek.map((day) => (
        <li 
          key={day} 
          onClick={(e) => {
            e.stopPropagation();
            onClick(employee, day);
          }}
          className="schedule-day-item"
          style={{ cursor: 'pointer' }}
        >
          {dayMapping[day]}: {schedule.schedule[day] || "Ne travaille pas"}
        </li>
      ))
    : null;

  return (
    <div className="employee-card">
      <h3>{employee.firstName} {employee.lastName}</h3>
      <p>Rôle: {getRoleInFrench(employee.role)}</p>
      <ul className="schedule-list">
        {displaySchedule}
      </ul>
    </div>
  );
};

export default EmployeeCard;
