import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import GlobalView from './GlobalView';
import ServerOrders from './ServerOrders';
import ServerSchedule from './ServerSchedule';
import MyPaiement from '../admin/MyPaiement';
import TakeOrder from './TakeOrder';
import './serverinterface.css';
import { FaSignOutAlt, FaClipboardList } from 'react-icons/fa';


const ServerInterface = () => {
  const [user, setUser] = useState(null);
  const [serverFirstName, setServerFirstName] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('home');
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  useEffect(() => {
    const fetchUserRole = async (currentUser) => {
      try {
        const userRef = doc(db, 'identifications', currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.role !== 'server') {
            setIsAuthorized(false);
            setTimeout(() => navigate('/login'), 3000);
          } else {
            setServerFirstName(userData.firstName);
          }
        } else {
          console.error('Utilisateur non trouvé.');
          setIsAuthorized(false);
          setTimeout(() => navigate('/login'), 3000);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du rôle :', error);
        setIsAuthorized(false);
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchUserRole(currentUser);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMenuOpen]);

  const handleLogout = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const handleLogoutCancel = () => {
    setShowLogoutConfirm(false);
  };

  const handleMenuClick = (section) => {
    setActiveSection(section);
    setIsMenuOpen(false);
  };

  if (!isAuthorized) {
    return (
      <div className="unauthorized-container">
        <div className="unauthorized-popup">
          <h2>Accès Refusé</h2>
          <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
          <p>Redirection en cours...</p>
        </div>
      </div>
    );
  };

  return (
    <div className="server-interface-container">
      <header className="server-header">
        <div className="icon-wrapper logout-left" onClick={handleLogout}>
          <FaSignOutAlt size={24} className="header-icon" />
        </div>

        <button className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <div></div>
          <div></div>
          <div></div>
        </button>
        
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`}>
          <div className="hamburger-item">
            <span 
              className="hamburger-category-label"
              onClick={() => handleMenuClick('home')}
            >
              Accueil
            </span>
          </div>
          <div className="hamburger-item">
            <span 
              className="hamburger-category-label"
              onClick={() => handleMenuClick('GlobalView')}
            >
              Vue Globale
            </span>
          </div>
          <div className="hamburger-item">
            <span 
              className="hamburger-category-label"
              onClick={() => handleMenuClick('MyPaiement')}
            >
              Gérer les paiements
            </span>
          </div>
          <div className="hamburger-item">
            <span 
              className="hamburger-category-label"
              onClick={() => handleMenuClick('ServerSchedule')}
            >
              Mon Planning
            </span>
          </div>
          <div className="hamburger-item logout-item">
            <button onClick={handleLogout} className="logout-button">
              Se déconnecter
            </button>
          </div>
        </div>

        <nav className="server-nav">
          <ul>
            <li onClick={() => handleMenuClick('home')} className={activeSection === 'home' ? 'active' : ''}>
              Accueil
            </li>
            <li onClick={() => handleMenuClick('GlobalView')} className={activeSection === 'GlobalView' ? 'active' : ''}>
              Vue Globale
            </li>
            <li onClick={() => handleMenuClick('MyPaiement')} className={activeSection === 'MyPaiement' ? 'active' : ''}>
              Gérer les paiements
            </li>
            <li onClick={() => handleMenuClick('ServerSchedule')} className={activeSection === 'ServerSchedule' ? 'active' : ''}>
              Mon Planning
            </li>
          </ul>
        </nav>

        <div className="icon-wrapper order-right" onClick={() => handleMenuClick('TakeOrder')}>
          <FaClipboardList size={24} className="header-icon" />
        </div>
      </header>

      {/* Popup de confirmation de déconnexion */}
      {showLogoutConfirm && (
        <div className="logout-confirm-overlay">
          <div className="logout-confirm-popup">
            <h3>Confirmation de déconnexion</h3>
            <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
            <div className="logout-confirm-buttons">
              <button onClick={handleLogoutConfirm} className="confirm-btn">
                Confirmer
              </button>
              <button onClick={handleLogoutCancel} className="cancel-btn">
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Contenu de la section active */}
      <main className="server-main">
        {user && serverFirstName && (
          <h2 className="welcome-message">Bienvenue, {serverFirstName} !</h2>
        )}
        {activeSection === 'GlobalView' && <GlobalView />}
        {activeSection === 'TakeOrder' && <TakeOrder />}
        {activeSection === 'ServerSchedule' && <ServerSchedule />}
        {activeSection === 'MyPaiement' && <MyPaiement />}
        {activeSection === 'home' && <ServerOrders />}
      </main>
    </div>
  );
};

export default ServerInterface;
