import React, { useEffect, useState } from 'react';
import { db } from '../../firebase'; // Assurez-vous que Firebase est configuré
import { collection, getDocs, query, where, doc, updateDoc, onSnapshot } from 'firebase/firestore';
import './WorkHours.css';

const WorkHours = () => {
  const [employees, setEmployees] = useState([]);
  const [hoursWorked, setHoursWorked] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [employeeExtraHours, setEmployeeExtraHours] = useState({});

  const getRoleDisplayName = (role) => {
    switch (role) {
      case 'bar':
        return 'Barman/Barmaid';
      case 'server':
        return 'Serveur';
      case 'cook':
        return 'Cuisinier';
      default:
        return role;
    }
  };

  const convertDecimalToHoursAndMinutes = (decimal) => {
    const hours = Math.floor(decimal);
    const minutes = Math.round((decimal - hours) * 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return minutes === 0 ? `${hours}h` : `${hours}h${formattedMinutes}`;
  };

  useEffect(() => {
    const fetchEmployeesAndSchedules = async () => {
      // Récupérer les employés
      const employeesCollection = collection(db, 'identifications');
      const employeesQuery = query(employeesCollection, where('role', '!=', 'admin'));
      const employeesSnapshot = await getDocs(employeesQuery);
  
      const employeesList = employeesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      setEmployees(employeesList);
  
      // Configurer un listener temps réel pour les horaires
      const schedulesCollection = collection(db, 'horaires');
      const unsubscribe = onSnapshot(schedulesCollection, (snapshot) => {
        const schedulesList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        const schedulesData = schedulesList.reduce((acc, schedule) => {
          acc[schedule.employeeId] = schedule;
          return acc;
        }, {});
  
        // Calculer les heures travaillées et heures supplémentaires
        calculateHoursWorked(employeesList, schedulesData);
        fetchEmployeeExtraHours(schedulesList);
      });
  
      // Nettoyage du listener lors du démontage du composant
      return () => unsubscribe();
    };

    const fetchEmployeeExtraHours = (schedulesList) => {
      const extraHoursData = schedulesList.reduce((acc, schedule) => {
        acc[schedule.employeeId] = schedule.heure_supp || 0;
        return acc;
      }, {});
      setEmployeeExtraHours(extraHoursData);
    };

    const calculateHoursWorked = (employeesList, schedulesData) => {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      const currentDay = today.getDate();
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
      const employeeHours = employeesList.reduce((acc, employee) => {
        const schedule = schedulesData[employee.id];
        if (!schedule) return acc;
    
        let totalHours = 0;
    
        for (let day = 1; day <= currentDay; day++) {
          const currentDate = new Date(currentYear, currentMonth, day);
          const dayName = dayNames[currentDate.getDay()];
    
          const workHours = schedule.schedule?.[dayName];
          if (workHours && workHours !== 'Ne travaille pas') {
            const timeRanges = workHours.split(' / '); // Diviser les plages horaires
    
            // Calcul des heures pour chaque plage
            const dayTotal = timeRanges.reduce((rangeTotal, range) => {
              const [start, end] = range.split(' - ');
              const [startHour, startMinute] = start.split(':').map(Number);
              const [endHour, endMinute] = end.split(':').map(Number);
    
              const startDate = new Date(currentYear, currentMonth, day, startHour, startMinute);
              const endDate = new Date(currentYear, currentMonth, day, endHour, endMinute);
    
              const now = new Date();
              if (endDate > now) {
                if (startDate <= now) {
                  const diffInMinutes = (now - startDate) / (1000 * 60);
                  return rangeTotal + diffInMinutes / 60;
                }
                return rangeTotal;
              }
    
              const diffInMinutes = (endDate - startDate) / (1000 * 60);
              return rangeTotal + diffInMinutes / 60;
            }, 0);
    
            totalHours += dayTotal;
          }
        }
    
        // Ajouter les heures supplémentaires
        const extraHours = schedule.heure_supp || 0;
        totalHours += extraHours;
    
        acc[employee.id] = totalHours;
        return acc;
      }, {});
    
      setHoursWorked(employeeHours);
      setIsLoading(false);
    };    

    fetchEmployeesAndSchedules();
  }, []);

  const handleDeleteExtraHours = async (employeeId) => {
    const scheduleRef = doc(db, 'horaires', employeeId);
    await updateDoc(scheduleRef, {
      heure_supp: 0,
    });

    alert('Heures supplémentaires supprimées.');
    setEmployeeExtraHours((prevState) => ({
      ...prevState,
      [employeeId]: 0,
    }));
  };

  const handleEditExtraHours = async (employeeId) => {
    const newExtraHours = parseFloat(employeeExtraHours[employeeId]);

    if (isNaN(newExtraHours)) {
      alert('Veuillez entrer un nombre valide pour les heures supplémentaires.');
      return;
    }

    const scheduleRef = doc(db, 'horaires', employeeId);

    try {
      await updateDoc(scheduleRef, {
        heure_supp: newExtraHours,
      });

      alert('Heures supplémentaires modifiées avec succès.');
      setEmployeeExtraHours((prevState) => ({
        ...prevState,
        [employeeId]: newExtraHours,
      }));
    } catch (error) {
      console.error('Erreur lors de la modification des heures supplémentaires :', error);
    }
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <div>
      <h2>Heures travaillées depuis le début du mois</h2>
      <div className="work-table-container">
        <table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Rôle</th>
              <th>Total heures</th>
              <th>Heures Supplémentaires</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr key={employee.id}>
                <td data-label="Nom">{employee.firstName} {employee.lastName}</td>
                <td data-label="Rôle">{getRoleDisplayName(employee.role)}</td>
                <td data-label="Total heures">{convertDecimalToHoursAndMinutes(hoursWorked[employee.id] || 0)}</td>
                <td data-label="Heures Supplémentaires">
                  <input
                    type="number"
                    value={employeeExtraHours[employee.id] || ''}
                    onChange={(e) => setEmployeeExtraHours((prevState) => ({
                      ...prevState,
                      [employee.id]: e.target.value,
                    }))}
                    placeholder="Heures supplémentaires"
                  />
                </td>
                <td data-label="Actions" className='actions-button-hour'>
                  <button onClick={() => handleEditExtraHours(employee.id)}>Modifier</button>
                  <button className='delete-table-hour' onClick={() => handleDeleteExtraHours(employee.id)}>Supprimer</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WorkHours;
