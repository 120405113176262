// src/components/ProtectedRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

// Composant pour protéger l'accès aux routes réservées aux utilisateurs authentifiés
const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);

  // Si l'utilisateur est en cours de chargement, vous pouvez afficher un loader
  if (loading) {
    return <div>Chargement...</div>;
  }

  // Si l'utilisateur n'est pas connecté, redirigez vers la page de connexion
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Si l'utilisateur est connecté, autorisez l'accès à la page
  return children;
};

export default ProtectedRoute;
