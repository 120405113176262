import React, { useState } from "react";
import { db } from "../firebase"; // Assurez-vous que la configuration Firebase est correcte
import { collection, addDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"; // Importer Timestamp de Firebase

// Fonction pour générer un ID client aléatoire
const generateRandomId = () => Math.floor(Math.random() * 10000);

// Fonction pour générer une date et une heure aléatoires
const generateRandomDateTime = () => {
  // Générer une date aléatoire dans le passé
  const randomDate = new Date();
  randomDate.setSeconds(randomDate.getSeconds() - Math.floor(Math.random() * 100000)); // Date aléatoire
  return randomDate;
};

const CreateClient = () => {
  const [prenom, setPrenom] = useState("");
  const [total, setTotal] = useState(0);
  const [heure, setHeure] = useState(""); // Ajouter un état pour l'heure
  const [date, setDate] = useState(""); // Ajouter un état pour la date

  // Fonction pour créer un client
  const createClient = async () => {
    // Si aucune heure n'est choisie, utiliser la date/heure actuelle
    const dateTime = date && heure ? new Date(`${date}T${heure}:00`) : generateRandomDateTime();
    
    const newClient = {
      total: total || 34, // Si le total est vide, utiliser 34 par défaut
      items: [
        {
          categorie: "entrees",
          quantity: 1,
          prix: 34,
          nom: "Mon entrée",
          cuisson: "",
        },
      ],
      idclient: generateRandomId(),
      commandeId: generateRandomId().toString(),
      prenom: prenom || "Gabriel", // Utilise "Gabriel" par défaut si rien n'est saisi
      // Utiliser Timestamp.fromDate pour convertir l'objet Date en Timestamp Firestore
      date: Timestamp.fromDate(dateTime),
    };

    try {
      // Ajouter un nouveau client dans la collection "total_commande"
      const docRef = await addDoc(collection(db, "total_commande"), newClient);
      console.log("Document écrit avec l'ID: ", docRef.id);
    } catch (e) {
      console.error("Erreur d'ajout du document: ", e);
    }
  };

  return (
    <div>
      <h1>Créer un Client</h1>
      <div>
        <label>Prénom:</label>
        <input
          type="text"
          value={prenom}
          onChange={(e) => setPrenom(e.target.value)}
          placeholder="Entrez le prénom"
        />
      </div>
      <div>
        <label>Total:</label>
        <input
          type="number"
          value={total}
          onChange={(e) => setTotal(Number(e.target.value))}
          placeholder="Entrez le total"
        />
      </div>
      <div>
        <label>Date:</label>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          placeholder="Choisissez la date"
        />
      </div>
      <div>
        <label>Heure:</label>
        <input
          type="time"
          value={heure}
          onChange={(e) => setHeure(e.target.value)}
        />
      </div>
      <button onClick={createClient}>Créer le client</button>
    </div>
  );
};

export default CreateClient;
