import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';  // Importation de useLocation pour récupérer l'URL
import { db } from '../../firebase';
import { collection, addDoc, doc, onSnapshot } from 'firebase/firestore';
import './Formulaire.css';

const Formulaire = () => {
  const [prenom, setPrenom] = useState('');
  const [table, setTable] = useState('');
  const [email, setEmail] = useState('');
  const [erreur, setErreur] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [accessError, setAccessError] = useState('');
  const [isPageActive, setIsPageActive] = useState(true);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [tableLimit, setTableLimit] = useState(100); // Limite par défaut

  // Récupérer le numéro de table depuis l'URL
  const location = useLocation();
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const tableNumberFromURL = params.get('table');

  useEffect(() => {
    if (tableNumberFromURL) {
      setTable(tableNumberFromURL);  // Préremplir l'input avec le numéro de table
    }
  }, [tableNumberFromURL]);

  useEffect(() => {
    const errorParam = params.get('error');
    if (errorParam === 'access_denied') {
      setAccessError('Vous devez d\'abord remplir le formulaire pour accéder à cette page.');
    }
  }, [params]);

  useEffect(() => {
    // Vérifier le paramètre success dans l'URL
    const success = params.get('success');
    if (success === 'order_completed') {
      setSuccessMessage('Merci pour votre commande ! À bientôt !');
    }
  }, [params]);

  useEffect(() => {
    const fetchTableLimit = () => {
      const docRef = doc(db, 'settings', 'nb_table');
      const unsubscribe = onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            const limit = docSnap.data().value;
            setTableLimit(limit || 100); // Définit la limite depuis Firestore ou 100 par défaut
          }
        },
        (error) => {
          console.error('Erreur lors de l\'écoute de nb_table :', error);
        }
      );
  
      return () => unsubscribe();
    };
  
    fetchTableLimit();
  }, []);

  const handleTableChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 1 && Number(value) <= tableLimit)) {
      setTable(value);
      setErreur('');
    } else {
      setErreur(`Le numéro de table doit être compris entre 1 et ${tableLimit}.`);
      setTable('');
    }
  };  

  const generateRandomId = () => {
    return Math.floor(1000 + Math.random() * 9000);  // Génère un nombre entre 1000 et 9999
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!prenom || !email || !table) {
      setErreur('Tous les champs sont requis.');
      return;
    }

    setErreur('');

    try {
      const clientId = generateRandomId();  // Génère un ID à 4 chiffres
      const docRef = await addDoc(collection(db, 'clients'), {
        prenom: prenom,
        table: parseInt(table, 10),
        email: email,
        idclient: clientId,  // Ajouter l'idclient dans Firestore
      });

      // Stocker les informations utilisateur dans le localStorage
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          prenom,
          table,
          email,
          id: docRef.id,  // ID Firebase
          idclient: clientId,  // Ajout de l'idclient ici
        })
      );

      console.log('Document écrit avec ID: ', docRef.id);
      setSuccess(true);
      navigate('/menu');
    } catch (error) {
      console.error('Erreur lors de l\'ajout du document: ', error);
      setErreur('Erreur lors de l\'ajout du document.');
    }
  };

    useEffect(() => {
        const docRef = doc(db, 'settings', 'pageStatus');
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
            setIsPageActive(docSnap.data().isActive);
        } else {
            console.error('Le document "pageStatus" n\'existe pas.');
        }
        setLoading(false);
        }, (error) => {
        console.error('Erreur lors de l\'écoute des modifications :', error);
        setLoading(false);
        });
    
        // Nettoyage de l'écouteur lors du démontage du composant
        return () => unsubscribe();
    }, []);  

  if (loading) return <p>Chargement...</p>;

  if (!isPageActive) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <h1>Page Désactivée</h1>
        <p>Cette page est actuellement désactivée. Revenez plus tard.</p>
      </div>
    );
  }

  return (
    <div className="client-form-container">
      {successMessage && <p className="client-success-message">{successMessage}</p>}
      {accessError && <p className="client-access-error">{accessError}</p>}
      {success ? (
        <h2 className="client-success-message">Maintenant, c'est le temps de commander</h2>
      ) : (
        <>
          <h2 className="client-form-title">Vos informations</h2>
          <form onSubmit={handleSubmit} className="client-form">
            <div className="client-input-group">
            <p className='your_commande'>Commande sur place</p>
              <label>Prénom :</label>
              <input
                type="text"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                required
              />
            </div>
            <div className="client-input-group">
              <label>Numéro de Table (1-{tableLimit}) :</label>
              <input
                type="tel"
                value={table}
                onChange={handleTableChange}
                required
              />
            </div>
            <div className="client-input-group">
              <label>Email :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {erreur && <p className="client-error-message">{erreur}</p>}
            <button type="submit" className="client-submit-btn">Continuer</button>
          </form>
        </>
      )}
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '10px 0',
          fontSize: '0.9em',
          color: '#555',
        }}
      >
        Cette application est développée par{' '}
        <a
          href="https://skaneat.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          SkanEat
        </a>
        .
      </div>
    </div>
  );
};

export default Formulaire;
