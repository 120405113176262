import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, query, getDocs, doc, setDoc } from 'firebase/firestore';
import { ReactComponent as PanierIcon } from '../../assets/panier.svg';
import './Menu.css';
import { FaPowerOff } from 'react-icons/fa';

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const getCategoryFromUrl = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get('category') || 'entrees'; // Retourne 'entrees' par défaut
  }, []);

  const [selectedCategory, setSelectedCategory] = useState('entrees');
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]); // État du panier
  const [isPopupVisible, setIsPopupVisible] = useState(false); // État pour la popup de sélection de quantité
  const [selectedProduct, setSelectedProduct] = useState(null); // Produit sélectionné pour ajout
  const [quantity, setQuantity] = useState(1); // Quantité sélectionnée
  const [successMessage, setSuccessMessage] = useState(''); // Message de succès
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // État pour la popup de succès
  const [cuisson, setCuisson] = useState(''); // État pour la cuisson
  const [isOrderPopupVisible, setIsOrderPopupVisible] = useState(false); // État pour la popup de commande
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false); // Ajouter cet état
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false); // État pour la popup de confirmation de suppression
  const [productToRemove, setProductToRemove] = useState(null); // Produit à supprimer

  const menuId = "ZifkqWN99oRgRNM0Nisz"; // Exemple de menuId, modifiez-le en fonction de votre logique

  const handleReset = () => {
    setShowLogoutConfirm(true); // Au lieu de déconnecter directement, afficher la popup
  };

  const confirmLogout = () => {
    localStorage.removeItem('userInfo');
    navigate('/');
  };

  const fetchProducts = async (category) => {
    const productsCollection = collection(db, `menu/${menuId}/${category}`);
    const q = query(productsCollection);
    const querySnapshot = await getDocs(q);
    const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setProducts(items);
  };

  useEffect(() => {
    fetchProducts(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    const category = getCategoryFromUrl();
    setSelectedCategory(category);
  }, [getCategoryFromUrl]);

  const handleAddToCart = (product) => {
    // Ajouter la catégorie au produit sélectionné
    const updatedProduct = {
      ...product,
      categorie: selectedCategory // Ajouter la catégorie actuelle au produit
    };
  
    setSelectedProduct(updatedProduct); // Enregistrer le produit mis à jour
    setIsPopupVisible(true);
    setCuisson(''); // Réinitialiser la cuisson à chaque fois qu'un produit est sélectionné
  };

  const handleAddQuantity = () => {
    const existingProduct = cart.find(item => item.id === selectedProduct.id && item.cuisson === cuisson);

    if (existingProduct) {
      existingProduct.quantity += quantity; // Ajouter à la quantité existante
    } else {
      setCart([...cart, { ...selectedProduct, quantity, cuisson }]);
    }

    setSuccessMessage(`${selectedProduct.nom} a été ajouté au panier.`);
    setShowSuccessPopup(true); // Afficher la popup de succès
    setIsPopupVisible(false);
    setQuantity(1); // Réinitialiser la quantité
    setCuisson(''); // Réinitialiser la cuisson

    // Masquer la popup de succès après 2 secondes
    setTimeout(() => {
      setShowSuccessPopup(false);
    }, 2000);
  };

  const calculateTotalPrice = () => {
    return cart.reduce((total, item) => total + item.prix * item.quantity, 0).toFixed(2);
  };

  const handleOrder = async () => {
    // Récupérer les informations de l'utilisateur
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  
    // Préparer les détails de la commande avec la catégorie ajoutée
    const commandeDetails = cart.map(item => ({
      nom: item.nom,
      quantity: item.quantity,
      prix: item.prix,
      cuisson: item.cuisson,
      categorie: item.categorie // Ajouter la catégorie
    }));
  
    try {
      // Ajouter la commande dans la sous-collection 'commande' du client
      const commandeRef = doc(collection(db, `clients/${userInfo.id}/commande`)); // Créer un nouveau document pour chaque commande
      await setDoc(commandeRef, {
        items: commandeDetails,
        total: calculateTotalPrice(),
        date: new Date(),
        statut: "Initialisée",
      });
      console.log('Commande ajoutée avec succès.');
      alert('Votre commande a été envoyée avec succès.');
      setCart([]); // Vider le panier après la commande
      navigate('/resume');
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la commande: ', error);
      alert('Erreur lors de l\'ajout de la commande.');
    }
  
    setIsOrderPopupVisible(false);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    // Mettre à jour l'URL avec la nouvelle catégorie
    navigate(`/menu?category=${category}`, { replace: true });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Mettre à jour la catégorie si l'URL change
  useEffect(() => {
    const category = getCategoryFromUrl();
    if (category) {
      setSelectedCategory(category);
    }
  }, [getCategoryFromUrl, location.search]);

  const handleUpdateQuantity = (productId, cuisson, newQuantity) => {
    if (newQuantity <= 0) {
      setProductToRemove({ id: productId, cuisson }); // Enregistrer le produit à supprimer
      setShowRemoveConfirm(true); // Afficher la popup de confirmation
    } else {
      setCart(cart.map(item => {
        if (item.id === productId && item.cuisson === cuisson) {
          return { ...item, quantity: newQuantity };
        }
        return item;
      }));
    }
  };

  const confirmRemove = () => {
    setCart(cart.filter(item => !(item.id === productToRemove.id && item.cuisson === productToRemove.cuisson)));
    setShowRemoveConfirm(false);
  };

  const cancelRemove = () => {
    setShowRemoveConfirm(false);
  };

  return (
    <div>
      <header className='menu-header'>
        <h1 className='menu-first-title'>Menu</h1>
        <nav className='menu-nav'>
          <ul className="menu-nav-list">
            <li onClick={() => handleCategoryClick('entrees')} className="menu-nav-item">Entrées</li>
            <li onClick={() => handleCategoryClick('plats')} className="menu-nav-item">Plats</li>
            <li onClick={() => handleCategoryClick('desserts')} className="menu-nav-item">Desserts</li>
            <li onClick={() => handleCategoryClick('boissons')} className="menu-nav-item">Boissons</li>
          </ul>
        </nav>
        <button className="logout-btn" onClick={handleReset}>
          <FaPowerOff />
        </button>
        <div className="cart-logo-container" onClick={() => {
            window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth'
            });
        }}>
            <PanierIcon className='cart-logo' />
            {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
        </div>
      </header>
      <main>
        <h2 className="menu-title">Faites votre choix parmi nos spécialités !</h2>
        <div className="product-list">
          {products.length > 0 ? (
            products.map(product => (
              <div key={product.id} className={`product-item ${product.actif ? '' : 'inactive'}`}>
                <h3>{product.nom}</h3>
                <p className='price'>Prix: {product.prix.toFixed(2)} €</p>
                <p className='description'>Description: {product.description}</p>
                <div className="product-photo-container">
                  {product.photo ? (
                    <img src={product.photo} alt={product.nom} className="product-photo" />
                  ) : (
                    <div className="no-image-text">Pas encore de visuel disponible</div>
                  )}
                </div>
                <button 
                  onClick={() => handleAddToCart(product)} 
                  disabled={!product.actif} // Désactiver si le produit n'est pas actif
                  className={product.actif ? 'add-to-cart-btn' : 'add-to-cart-btn disabled'} // Appliquer un style désactivé
                  >
                  {product.actif ? 'Ajouter' : 'Indisponible'}
                </button>
                <hr />
              </div>
            ))
          ) : (
            <p>Aucun produit disponible dans cette catégorie.</p>
          )}
        </div>

        {/* Affichage du panier */}
        <div className="menu-cart-section">
          <h2>Votre Panier</h2>
          {cart.length > 0 ? (
            cart.map(item => (
              <div key={item.id} className="menu-cart-item">
                <div className="menu-cart-item-info">
                  <p>{item.nom} {item.cuisson && `- Cuisson: ${item.cuisson}`}</p>
                  <p className="menu-cart-item-price">{(item.prix * item.quantity).toFixed(2)} €</p>
                </div>
                <div className="menu-cart-quantity-controls">
                  <button 
                    className="quantity-btn"
                    onClick={() => handleUpdateQuantity(item.id, item.cuisson, item.quantity - 1)}
                  >
                    -
                  </button>
                  <span className="quantity-display">{item.quantity}</span>
                  <button 
                    className="quantity-btn"
                    onClick={() => handleUpdateQuantity(item.id, item.cuisson, item.quantity + 1)}
                  >
                    +
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>Votre panier est vide.</p>
          )}
          <h3>Total: {calculateTotalPrice()} €</h3>
          <button onClick={() => setIsOrderPopupVisible(true)} disabled={cart.length === 0} className="order-btn">Commander</button>
        </div>

        {/* Popup de commande */}
        {isOrderPopupVisible && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Confirmation de commande</h3>
              <p>Voulez-vous confirmer votre commande ?</p>
              <div className="menu-popup-buttons">
                <button onClick={handleOrder}>Commander</button>
                <button onClick={() => setIsOrderPopupVisible(false)}>Annuler</button>
              </div>
            </div>
          </>
        )}

        {/* Popup pour sélectionner la quantité */}
        {isPopupVisible && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Choisissez la quantité pour {selectedProduct.nom}</h3>
              <input
                type="tel"
                value={quantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
                min="1"
              />
              
              {/* Si la description du produit contient "choisir la cuisson", afficher les options de cuisson */}
              {selectedProduct.description.includes('choisir la cuisson') && (
                <div>
                  <label>Choisissez la cuisson:</label>
                  <select value={cuisson} onChange={(e) => setCuisson(e.target.value)} required>
                    <option value="">Sélectionnez la cuisson</option>
                    <option value="bleu">Bleu</option>
                    <option value="saignant">Saignant</option>
                    <option value="à point">À point</option>
                    <option value="bien cuit">Bien cuit</option>
                  </select>
                </div>
              )}
              <div className="menu-popup-buttons">
                <button onClick={handleAddQuantity} disabled={selectedProduct.description.includes('choisir la cuisson') && !cuisson}>
                  Ajouter au panier
                </button>
                <button onClick={() => setIsPopupVisible(false)}>Annuler</button>
              </div>
              </div>
          </>
        )}

        {/* Message de succès */}
        {showSuccessPopup && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup-confirmation">
              <p>{successMessage}</p>
            </div>
          </>
        )}

        {/* Popup de confirmation de déconnexion */}
        {showLogoutConfirm && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Confirmation de déconnexion</h3>
              <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
              <div className="menu-popup-buttons">
                <button onClick={confirmLogout}>Confirmer</button>
                <button onClick={() => setShowLogoutConfirm(false)}>Annuler</button>
              </div>
            </div>
          </>
        )}

        {/* Popup de confirmation de suppression */}
        {showRemoveConfirm && (
          <>
            <div className="menu-popup-overlay" />
            <div className="menu-popup">
              <h3>Confirmation de suppression</h3>
              <p>Êtes-vous sûr de vouloir supprimer cet élément du panier ?</p>
              <div className="menu-popup-buttons">
                <button onClick={confirmRemove}>Confirmer</button>
                <button onClick={cancelRemove}>Annuler</button>
              </div>
            </div>
          </>
        )}
      </main>
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '10px 0',
          fontSize: '0.9em',
          color: '#555',
        }}
      >
        Cette application est développée par{' '}
        <a
          href="https://skaneat.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          SkanEat
        </a>
        .
      </div>
    </div>
  );
};

export default Menu;
