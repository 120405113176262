import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebase'; // Firebase Firestore import
import { collection, query, where, onSnapshot, updateDoc, doc, getDoc, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { ReactComponent as LogoutIcon } from '../../assets/logout.svg';
import './bardashboard.css';

const BarInterface = () => {
  const [acceptedItems, setAcceptedItems] = useState([]); // Plats acceptés
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [serverFirstName, setServerFirstName] = useState('');
  const [schedule, setSchedule] = useState(null); // État pour stocker l'emploi du temps de l'utilisateur
  const [totalHours, setTotalHours] = useState(0); // Total des heures travaillées
  const [isAuthorized, setIsAuthorized] = useState(true); // Vérifie si l'utilisateur est autorisé
  const [showSchedule, setShowSchedule] = useState(false);

  useEffect(() => {
    const fetchUserRole = async (currentUser) => {
      try {
        const userRef = doc(db, 'identifications', currentUser.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          if (userData.role !== 'bar') {
            setIsAuthorized(false);
            setTimeout(() => navigate('/login'), 3000); // Redirige après 3 secondes
          } else {
            setServerFirstName(userData.firstName);
          }
        } else {
          console.error('Utilisateur non trouvé.');
          setIsAuthorized(false);
          setTimeout(() => navigate('/login'), 3000);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du rôle :', error);
        setIsAuthorized(false);
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchUserRole(currentUser);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Récupérer l'emploi du temps de l'utilisateur connecté
  useEffect(() => {
    const fetchSchedule = async () => {
      if (user) {
        try {
          const scheduleRef = collection(db, 'horaires');
          const q = query(scheduleRef, where('employeeId', '==', user.uid));
          const querySnapshot = await getDocs(q);

          querySnapshot.forEach((doc) => {
            setSchedule(doc.data().schedule); // Extraire l'emploi du temps
          });
        } catch (error) {
          console.error("Erreur lors de la récupération de l'emploi du temps :", error);
        }
      }
    };

    fetchSchedule();
  }, [user]);

  // Récupérer les items des commandes acceptées et écouter les changements en temps réel
  useEffect(() => {
    const fetchCommandItems = async () => {
      try {
        const clientsCollection = collection(db, 'clients');

        // Écouter les modifications en temps réel sur chaque client
        const unsubscribe = onSnapshot(clientsCollection, (clientsSnapshot) => {
          clientsSnapshot.forEach((clientDoc) => {
            const clientId = clientDoc.id;
            const commandesCollection = collection(db, `clients/${clientId}/commande`);
            const qAccepted = query(commandesCollection, where('statut', '==', 'Accepté'));

            // Écouter en temps réel des commandes acceptées
            onSnapshot(qAccepted, (commandesSnapshot) => {
              let updatedItems = [];
              commandesSnapshot.forEach((commandeDoc) => {
                const commandeData = commandeDoc.data();
                const commandeItems = commandeData.items.map((item) => ({
                  ...item,
                  clientId,
                  commandeId: commandeDoc.id,
                  table: commandeData.table,
                  prenom: commandeData.prenom,
                  email: commandeData.email,
                }));
                updatedItems = [...updatedItems, ...commandeItems];
              });

              // Mettre à jour l'état local des plats acceptés
              setAcceptedItems(updatedItems);
            });
          });
        });

        return () => unsubscribe(); // Nettoyer l'abonnement lorsque le composant se démonte
      } catch (error) {
        console.error('Erreur lors de la récupération des items des commandes acceptées:', error);
      }
    };

    fetchCommandItems();
  }, []);

  const calculateTotalHours = useCallback(async () => {
    if (!schedule || !user) return;
  
    const currentDate = new Date();
    const currentDay = currentDate.getDate(); // Jour actuel du mois
  
    const daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const hoursWorked = [];
  
    // On va vérifier chaque jour de la semaine jusqu'au jour actuel du mois
    for (let i = 0; i < currentDay; i++) {
      const dayOfWeek = daysInWeek[i % 7]; // Calculer le jour de la semaine pour la date
      const daySchedule = schedule[dayOfWeek];
  
      if (daySchedule && daySchedule !== "Ne travaille pas") {
        const periods = daySchedule.split(" / "); // Diviser les périodes par "/"
        let dailyHours = 0;
      
        periods.forEach((period) => {
          const [start, end] = period.split(" - ");
          const startTime = new Date(`2023-12-01T${start}:00`);
          const endTime = new Date(`2023-12-01T${end}:00`);
          const hours = (endTime - startTime) / 1000 / 60 / 60; // Conversion en heures
          dailyHours += hours;
        });
      
        hoursWorked.push(dailyHours); // Ajouter les heures du jour au tableau
      }      
    }
  
    let total = hoursWorked.reduce((acc, hours) => acc + hours, 0); // Calcul du total
  
    // Ajouter les heures supplémentaires depuis la base de données
    try {
      const scheduleRef = collection(db, 'horaires');
      const q = query(scheduleRef, where('employeeId', '==', user.uid));
      const querySnapshot = await getDocs(q);
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.heure_supp) {
          total += parseFloat(data.heure_supp);
        }
  
        // Mettre à jour le total dans la base de données
        updateDoc(doc.ref, { total_hours: total });
      });
  
      setTotalHours(total);
    } catch (error) {
      console.error("Erreur lors de la récupération/mise à jour des heures supplémentaires :", error);
    }
  }, [schedule, user]); // `calculateTotalHours` dépend de `schedule` et `user`  

  useEffect(() => {
    calculateTotalHours(); // Appeler le calcul des heures dès que l'emploi du temps est récupéré
  }, [schedule, calculateTotalHours]);

  const toggleDrinkStatus = async (clientId, commandeId, dishName, newStatus) => {
    try {
      const commandeRef = doc(db, `clients/${clientId}/commande`, commandeId);
      const commandeSnap = await getDoc(commandeRef);
  
      if (commandeSnap.exists()) {
        const commandeData = commandeSnap.data();
  
        // Mettre à jour le champ `drink_statut`
        const updatedItems = commandeData.items.map((item) =>
          item.nom === dishName
            ? { ...item, drink_statut: newStatus }
            : item
        );
  
        // Mise à jour dans la base de données
        await updateDoc(commandeRef, { items: updatedItems });
  
        // Mettre à jour l'état local
        setAcceptedItems((prevItems) =>
          prevItems.map((item) =>
            item.clientId === clientId && item.commandeId === commandeId && item.nom === dishName
              ? { ...item, drink_statut: newStatus }
              : item
          )
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut du plat :", error);
    }
  };

  const formatHours = (hours) => {
    const fullHours = Math.floor(hours); // Partie entière des heures
    const minutes = Math.round((hours - fullHours) * 60); // Partie décimale convertie en minutes
    return `${fullHours}h${minutes.toString().padStart(2, '0')}`; // Format h:mm avec zéro-padding
  };

  // Afficher l'emploi du temps sous forme de tableau
  const renderSchedule = () => {
    if (!schedule) return <p>Chargement de l'emploi du temps...</p>;
  
    const daysInFrench = {
      Sunday: "Dimanche",
      Monday: "Lundi",
      Tuesday: "Mardi",
      Wednesday: "Mercredi",
      Thursday: "Jeudi",
      Friday: "Vendredi",
      Saturday: "Samedi"
    };
  
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
    return (
      <table className="bar-schedule-table">
        <thead>
          <tr>
            {dayNames.map((day) => (
              <th key={day}>{daysInFrench[day]}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {dayNames.map((day) => (
              <td key={day}>
                {schedule[day] === 'Ne travaille pas' ? 'Ne travaille pas' : schedule[day]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  if (!isAuthorized) {
    return (
      <div className="unauthorized-container">
        <div className="unauthorized-popup">
          <h2>Accès Refusé</h2>
          <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
          <p>Redirection en cours...</p>
        </div>
      </div>
    );
  };

  return (
    <div className="cook-interface-container">
      <h1 className="cook-interface-title">Interface Barman/Barmaid</h1>
      <div className="logout-icon-spe" onClick={() => signOut(auth).then(() => navigate('/login'))}>
          <LogoutIcon />
      </div>
      {user && serverFirstName && (
        <h2>Bienvenue, {serverFirstName} !</h2>
      )}

      <h2 className="section-title">Boissons des commandes acceptées</h2>
      {acceptedItems.length > 0 ? (
        <div className="categories">
          {['boissons'].map((category) => (
            <div key={category} className="category">
              <h3>{category.toUpperCase()}</h3>
              {acceptedItems
                .filter((item) => item.categorie === category)
                .map((item, index) => (
                  <div key={`${item.commandeId}-${index}`} className="dish-card">
                    <p>
                      <strong>{item.nom}</strong> - Quantité : {item.quantity}
                    </p>
                    {item.drink_statut === 'prepared_drink' ? (
                      <>
                        <span className="status prepared">Boisson préparée</span>
                        <button
                          className="action-button not-ready"
                          onClick={() => toggleDrinkStatus(item.clientId, item.commandeId, item.nom, 'not_prepared')}
                        >
                          Non préparé
                        </button>
                      </>
                    ) : (
                      <button
                        className="action-button prepare"
                        onClick={() => toggleDrinkStatus(item.clientId, item.commandeId, item.nom, 'prepared_drink')}
                      >
                        Boisson préparée
                      </button>
                    )}
                  </div>
                ))}
            </div>
          ))}
        </div>
      ) : (
        <p className='no-command'>Aucune commande acceptée à afficher.</p>
      )}
      <button 
        className="view-hours-button"
        onClick={() => setShowSchedule(!showSchedule)}
      >
        Voir mes heures
      </button>
      {showSchedule && (
        <div className="schedule-popup-overlay">
          <div className="schedule-popup">
            <button className="close-popup-button" onClick={() => setShowSchedule(false)}>X</button>
            <h2 className="section-title">Emploi du temps</h2>
            <div className="schedule-popup-content">
              {renderSchedule()}
            </div>

            <h2 className="section-title">Total des heures travaillées ce mois</h2>
            <p>Total des heures travaillées : {formatHours(totalHours)} heures</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BarInterface;
