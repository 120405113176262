import React, { useEffect, useState } from 'react';
import { auth } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { db } from '../../firebase';
import { doc, setDoc, collection, deleteDoc, onSnapshot } from 'firebase/firestore';
import './admin.css';

const AddBarForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bars, setBars] = useState([]);
  const [selectedBar, setSelectedBar] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const barsCollection = collection(db, 'identifications');
    
    const unsubscribe = onSnapshot(barsCollection, (snapshot) => {
      const barList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBars(barList);
    }, (error) => {
      console.error("Erreur lors de la récupération des barmans : ", error);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      if (selectedBar) {
        // Mise à jour uniquement du nom et du prénom
        const barDoc = doc(db, 'identifications', selectedBar.id);
        await setDoc(barDoc, {
          ...selectedBar, // Conserver les données existantes
          firstName,
          lastName,
        });

        setSuccess('Nom et prénom mis à jour avec succès !');
      } else {
        // Ajouter un nouveau barman
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        await setDoc(doc(db, 'identifications', user.uid), {
          email: user.email,
          firstName,
          lastName,
          role: 'bar',
          createdAt: new Date(),
        });
        setSuccess('Barman ajouté avec succès !');
      }

      // Réinitialiser les champs
      setEmail('');
      setPassword('');
      setFirstName('');
      setLastName('');
      setSelectedBar(null);
    } catch (error) {
      setError('Erreur lors de l\'ajout ou de la mise à jour du barman : ' + error.message);
    }
  };

  const handleEdit = (bar) => {
    setFirstName(bar.firstName);
    setLastName(bar.lastName);
    setSelectedBar(bar);
  };

  const handleDelete = async (serverId) => {
    try {
      // Appeler le backend pour supprimer l'utilisateur dans Firebase Authentication
      const response = await fetch('https://skaneat-app.vercel.app/delete-user', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: serverId }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Erreur lors de la suppression de l’utilisateur.');
      }
  
      // Supprimer l'utilisateur dans Firestore Database
      await deleteDoc(doc(db, 'identifications', serverId));
      await deleteDoc(doc(db, 'horaires', serverId));
  
      setSuccess('Serveur supprimé avec succès !');
    } catch (error) {
      setError('Erreur lors de la suppression du barman/barmaid : ' + error.message);
    }
  };  

  return (
    <div className="server-form-container">
      <h2>{selectedBar ? 'Modifier un barman' : 'Ajouter un barman'}</h2>
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nom :</label>
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Prénom :</label>
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>

        {!selectedBar && (
          <>
            <div>
              <label>Email du barman :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Mot de passe du barman :</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
          </>
        )}

        <button type="submit">
          {selectedBar ? 'Mettre à jour le barman' : 'Ajouter un barman'}
        </button>
      </form>

      <div className="server-list-container">
        <h3>Barmans Enregistrés :</h3>
        <ul>
          {bars
            .filter((bar) => bar.role === 'bar') // Filtre les barmans avec le rôle 'bar'
            .map((bar) => (
              <li key={bar.id}>
                {bar.firstName} {bar.lastName} - {bar.email}
                <div>
                  <button onClick={() => handleEdit(bar)}>Modifier</button>
                  <button onClick={() => handleDelete(bar.id)}>Supprimer</button>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default AddBarForm;
