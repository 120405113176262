import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './CashPaiement.css';
import { ReactComponent as NumberIcon } from '../../assets/number.svg';
import { ReactComponent as PaymentIcon } from '../../assets/payment.svg';
import { ReactComponent as WalkIcon } from '../../assets/walk.svg';

const CashPaiement = () => {
  const [isWaiting, setIsWaiting] = useState(true); // Statut de l'attente
  const [paymentStatus, setPaymentStatus] = useState('');
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem('userInfo')); // Utilisateur actuel
  
  useEffect(() => {
    // Créer un nouveau paiement dans la base de données (en_attente)
    const createPayment = async () => {
      try {
        const clientRef = doc(db, 'clients', userInfo.id);
        await updateDoc(clientRef, {
          paiement: { status: 'en_attente' }, // Statut initial du paiement
        });
      } catch (error) {
        console.error('Erreur lors de la création du paiement:', error);
      }
    };
  
    // Suivre le statut du paiement
    const trackPaymentStatus = async () => {
      const clientRef = doc(db, 'clients', userInfo.id);
  
      onSnapshot(clientRef, (docSnapshot) => {
        const clientData = docSnapshot.data();
        const status = clientData?.paiement?.status || '';
  
        if (status === 'payé') {
          setPaymentStatus('Paiement effectué');
          setIsWaiting(false);
  
          // Rediriger vers la page de confirmation après paiement
          setTimeout(() => {
            navigate('/confirmation', { state: { paymentIntent: { status: 'succeeded' } } });
          }, 2000); // Attendre 2 secondes avant de rediriger
        } else if (status === 'en_attente') {
          setPaymentStatus('En attente de paiement...');
        }
      });
    };
  
    createPayment();
    trackPaymentStatus();
  }, [userInfo.id, navigate]);  

  return (
    <div className="cashpaiement-container">
      <h1>Statut du paiement</h1>
      {isWaiting ? (
        <div className="loading-container">
          <div className="textWrapper">
            <p className="text">{paymentStatus}</p>
            <div className="invertbox"></div>
          </div>
        </div>
      ) : (
        <div>
          <p>{paymentStatus}</p>
          <button onClick={() => navigate('/confirmation')}>Retour à la confirmation</button>
        </div>
      )}
      <div className="instructions">
        <div className="instruction-step">
          <WalkIcon />
          <span>Rendez-vous à la caisse.</span>
        </div>
        <div className="instruction-step">
          <NumberIcon />
          <span>Communiquez votre numéro de commande.</span>
        </div>
        <div className="instruction-step">
          <PaymentIcon />
          <span>Payez par carte, espèces ou ticket resto.</span>
        </div>
      </div>
    </div>
  );
};

export default CashPaiement;
