import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './ClientId.css';

const ClientId = () => {
  const [searchId, setSearchId] = useState('');
  const [allClients, setAllClients] = useState([]); // Pour stocker tous les clients
  const [filteredClients, setFilteredClients] = useState([]); // Pour stocker les clients filtrés
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null); // Pour le client sélectionné
  const [popupVisible, setPopupVisible] = useState(false); // Pour gérer la popup
  const [sortCriteria, setSortCriteria] = useState('date'); // Critère de tri par défaut
  const [sortDirection, setSortDirection] = useState('desc'); // 'asc' ou 'desc'

  useEffect(() => {
    const fetchAllClients = async () => {
      setLoading(true);
      try {
        const totalCommandeRef = collection(db, 'total_commande');
        const querySnapshot = await getDocs(totalCommandeRef);
        const clients = [];
        
        querySnapshot.forEach((doc) => {
          clients.push({ id: doc.id, ...doc.data() });
        });
        
        setAllClients(clients);
        setFilteredClients(clients.sort((a, b) => b.date.toDate() - a.date.toDate()));
      } catch (error) {
        console.error('Erreur lors du chargement des clients :', error);
      }
      setLoading(false);
    };

    fetchAllClients();
  }, []);

  // Fonction pour rechercher les commandes avec l'ID client
  const handleSearch = (searchValue) => {
    if (!searchValue.trim()) {
      setFilteredClients(allClients);
      return;
    }

    const searchLower = searchValue.trim().toLowerCase();
    const filtered = allClients.filter(client => 
      client.idclient.toString().toLowerCase().includes(searchLower) ||
      client.prenom.toLowerCase().includes(searchLower) ||
      client.date.toDate().toLocaleString().toLowerCase().includes(searchLower) ||
      client.total.toString().includes(searchLower)
    );
    setFilteredClients(filtered);
  };

  // Fonction pour afficher les commandes du client
  const handleClientClick = (clientId) => {
    const clientOrders = allClients.filter(client => client.idclient === clientId);
    setSelectedClient(clientOrders);
    setPopupVisible(true);
  };

  // Calculer le total global
  const calculateTotalGlobal = (orders) => {
    return orders.reduce((total, order) => total + order.total, 0);
  };

  // Fermer la popup
  const closePopup = () => {
    setPopupVisible(false);
    setSelectedClient(null);
  };

  // Fonction pour trier les clients
  const sortClients = (criteria) => {
    // Supprimer la classe fade-in et ajouter fade-out
    const rows = document.querySelectorAll('.clients-table tbody tr');
    rows.forEach(row => {
        row.classList.remove('fade-in');
        row.classList.add('fade-out');
    });

    setTimeout(() => {
        const newDirection = criteria === sortCriteria 
            ? sortDirection === 'asc' ? 'desc' : 'asc'
            : 'desc';
        
        setSortDirection(newDirection);
        setSortCriteria(criteria);
        
        const sortedClients = [...filteredClients].sort((a, b) => {
            let comparison = 0;
            if (criteria === 'date') {
                comparison = b.date.toDate() - a.date.toDate();
            } else if (criteria === 'idclient') {
                comparison = a.idclient - b.idclient;
            } else if (criteria === 'prenom') {
                comparison = a.prenom.localeCompare(b.prenom);
            } else if (criteria === 'total') {
                comparison = a.total - b.total;
            }
            return newDirection === 'asc' ? comparison : -comparison;
        });
        
        setFilteredClients(sortedClients);

        // Réinitialiser l'animation pour chaque nouveau tri
        setTimeout(() => {
            const newRows = document.querySelectorAll('.clients-table tbody tr');
            newRows.forEach(row => {
                row.classList.remove('fade-out');
                row.classList.add('fade-in');
            });
        }, 50);
    }, 300);
  };

  return (
    <div className="client-id-container">
      <h1 className="title">Tous mes clients</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Rechercher un client..."
          value={searchId}
          onChange={(e) => {
            setSearchId(e.target.value);
            handleSearch(e.target.value);
          }}
          className="input"
        />
      </div>

      {loading && <p className="loading">Chargement...</p>}

      {!loading && (
        <div className="table-container">
          <table className="clients-table">
            <thead>
              <tr>
                <th 
                    onClick={() => sortClients('idclient')}
                    className={sortCriteria === 'idclient' ? `sort-${sortDirection}` : ''}
                >
                    ID Client
                </th>
                <th 
                    onClick={() => sortClients('prenom')}
                    className={sortCriteria === 'prenom' ? `sort-${sortDirection}` : ''}
                >
                    Prénom
                </th>
                <th 
                    onClick={() => sortClients('date')}
                    className={sortCriteria === 'date' ? `sort-${sortDirection}` : ''}
                >
                    Date
                </th>
                <th 
                    onClick={() => sortClients('total')}
                    className={sortCriteria === 'total' ? `sort-${sortDirection}` : ''}
                >
                    Total
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredClients.map((client) => (
                <tr key={client.id} onClick={() => handleClientClick(client.idclient)}>
                  <td>{client.idclient}</td>
                  <td>{client.prenom}</td>
                  <td>{client.date.toDate().toLocaleString()}</td>
                  <td>{client.total} €</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!loading && filteredClients.length === 0 && (
        <p className="no-results">Aucun résultat trouvé pour cet ID client.</p>
      )}

      {popupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button className="close-btn" onClick={closePopup}>
              &times;
            </button>
            <h2>Détails des commandes</h2>
            {selectedClient && selectedClient.length > 0 ? (
              <>
                <ul>
                  {selectedClient.map((order, index) => (
                    <li key={index}>
                      <h3>Commande #{order.idclient}</h3>
                      <p><strong>Nom :</strong> {order.prenom}</p>
                      <p><strong>Date :</strong> {order.date.toDate().toLocaleString()}</p>
                      <p><strong>Total de la commande :</strong> {order.total} €</p>
                      <p><strong>Produits :</strong></p>
                      <ul>
                        {order.items.map((item, idx) => (
                          <li key={idx}>
                            {item.nom} - {item.quantity}x - {item.prix} €
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
                <h3>Total global : {calculateTotalGlobal(selectedClient)} €</h3>
              </>
            ) : (
              <p>Aucune commande trouvée.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientId;
