import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { signOut } from 'firebase/auth';
import AddDishForm from './AddDishForm';
import ViewMenuItems from './ViewMenuItems';
import AddServerForm from './AddServerForm';
import AddCookForm from './AddCookForm';
import AddBarForm from './AddBarForm';
import Statistics from './Statistics';
import AdminHome from './AdminHome';
import WeekInvoices from './WeekInvoices';
import ManageSchedule from './schedule/ManageSchedule';
import WorkHours from './WorkHours';
import ClientId from './ClientId';
import Avis from './Avis';
import MyPaiement from './MyPaiement';
import ComparaisonDate from './ComparaisonDate';
import ManageTables from './ManageTables';
import './AdminDashboard.css';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuId = "ZifkqWN99oRgRNM0Nisz";
  const [isPageActive, setIsPageActive] = useState(true);

  const fetchPageStatus = useCallback(async () => {
    try {
      const docRef = doc(db, 'settings', 'pageStatus');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setIsPageActive(docSnap.data().isActive);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération de l\'état de la page :', error);
    }
  }, []);
  
  // Fonction pour basculer l'état de la page
  const togglePageStatus = async () => {
    try {
      const newStatus = !isPageActive;
      const docRef = doc(db, 'settings', 'pageStatus');
      await updateDoc(docRef, { isActive: newStatus });
      setIsPageActive(newStatus);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'état de la page :', error);
    }
  };
  
  // Récupération de l'état lors du montage
  useEffect(() => {
    fetchPageStatus();
  }, [fetchPageStatus]);

  const getSectionFromUrl = useCallback(() => {
    const params = new URLSearchParams(location.search);
    return params.get('section') || 'accueil';
  }, [location]);

  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [activeSection, setActiveSection] = useState(getSectionFromUrl());
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'identifications', user.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();
            if (userData.role !== 'admin') {
              setIsAuthorized(false);
              setTimeout(() => navigate('/login'), 3000); // Redirige après 3 secondes
            }
          } else {
            setIsAuthorized(false);
            setTimeout(() => navigate('/login'), 3000);
          }
        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Erreur lors de la vérification du rôle :', error);
        setIsAuthorized(false);
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    fetchUserRole();
  }, [navigate]);

  useEffect(() => {
    setActiveSection(getSectionFromUrl());
  }, [location, getSectionFromUrl]);

  const navigationStructure = [
    {
      id: 'accueil',
      label: 'Accueil',
      isDirectLink: true
    },
    {
      id: 'menu',
      label: 'Mon menu',
      subCategories: [
        { path: 'addDish', label: 'Ajouter un plat' },
        { path: 'viewMenu', label: 'Gérer mon menu' }
      ]
    },
    {
      id: 'workers',
      label: 'Mes workers',
      subCategories: [
        { path: 'manageServers', label: 'Gérer les serveurs' },
        { path: 'manageCooks', label: 'Gérer les cuisiniers' },
        { path: 'manageBarmaids', label: 'Gérer les barmans' }
      ]
    },
    {
      id: 'statistics',
      label: 'Mes statistiques',
      subCategories: [
        { path: 'statistics', label: 'Voir les statistiques' },
        { path: 'comparaison', label: 'Faire mes comparaisons' }
      ]
    },
    {
      id: 'invoices',
      label: 'Mes factures',
      subCategories: [
        { path: 'weekInvoices', label: 'Visualiser mes factures' },
      ]
    },
    {
      id: 'planning',
      label: 'Mon planning',
      subCategories: [
        { path: 'manageSchedule', label: 'Gérer le planning' },
        { path: 'WorkHours', label: 'Heures de travail' }
      ]
    },
    {
      id: 'clients',
      label: 'Mes clients',
      subCategories: [
        { path: 'myClient', label: 'Voir les clients' },
        { path: 'avis', label: 'Mes avis' },
        { path: 'ManageTables', label: 'Gérer mes tables' } 
      ]
    },
    {
      id: 'paiment',
      label: 'Mes paiments',
      subCategories: [
        { path: 'myPaiement', label: 'Voir les paiements en attente' },
      ]
    }
  ];

  const handleSectionChange = (path) => {
    setActiveSection(path);
    navigate(`/admin-dashboard?section=${path}`);
    setIsMenuOpen(false);
    setOpenSubMenu(null);
  };

  const handleLogoutClick = () => {
    setShowLogoutConfirm(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Erreur lors de la déconnexion:', error);
    }
  };

  const handleLogoutCancel = () => {
    setShowLogoutConfirm(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (isMenuOpen) {
      setOpenSubMenu(null);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
        setOpenSubMenu(null);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMenuOpen]);

  const handleSubMenuToggle = (categoryId) => {
    setOpenSubMenu(openSubMenu === categoryId ? null : categoryId);
  };

  const handleDesktopNavigation = (path) => {
    handleSectionChange(path);
  };

  if (!isAuthorized) {
    return (
      <div className="unauthorized-container">
        <div className="unauthorized-popup">
          <h2>Accès Refusé</h2>
          <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
          <p>Redirection en cours...</p>
        </div>
      </div>
    );
  }  

  return (
    <div className="admin-dashboard">
      <nav className="desktop-nav">
        {navigationStructure.map((category) => (
          <div 
            key={category.id} 
            className="nav-item"
            onClick={() => category.isDirectLink ? handleDesktopNavigation(category.id) : null}
            style={{ cursor: category.isDirectLink ? 'pointer' : 'default' }}
          >
            <span>
              {category.label}
            </span>
            {category.subCategories && (
              <div className="nav-dropdown">
                {category.subCategories.map((subCat) => (
                  <button
                    key={subCat.path}
                    onClick={(e) => {
                      e.stopPropagation(); // Empêche la propagation au parent
                      handleDesktopNavigation(subCat.path);
                    }}
                  >
                    {subCat.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        ))}
        <div className="nav-item" onClick={handleLogoutClick}>
          Se déconnecter
        </div>
      </nav>

      <button className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </button>

      <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`}>
        {navigationStructure.map((category) => (
          <div 
            key={category.id}
            className={`hamburger-item ${activeSection === category.id ? 'active' : ''}`}
          >
            <span 
              className="hamburger-category-label"
              onClick={category.subCategories ? () => handleSubMenuToggle(category.id) : () => handleSectionChange(category.id)}
            >
              {category.label}
            </span>
            {openSubMenu === category.id && category.subCategories && (
              <div className="hamburger-dropdown-content">
                {category.subCategories.map((subCat) => (
                  <div key={subCat.path} className="hamburger-sub-category">
                    <button
                      onClick={() => {
                        handleSectionChange(subCat.path);
                      }}
                      className={activeSection === subCat.path ? 'active' : ''}
                    >
                      {subCat.label}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}

        <div className="hamburger-item logout-item">
          <button onClick={handleLogoutClick} className="logout-button">
            Se déconnecter
          </button>
        </div>
      </div>

      {showLogoutConfirm && (
        <div className="logout-confirm-overlay">
          <div className="logout-confirm-popup">
            <h3>Confirmation de déconnexion</h3>
            <p>Êtes-vous sûr de vouloir vous déconnecter ?</p>
            <div className="logout-confirm-buttons">
              <button onClick={handleLogoutConfirm} className="confirm-btn">
                Confirmer
              </button>
              <button onClick={handleLogoutCancel} className="cancel-btn">
                Annuler
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="section-content">
        {activeSection === 'accueil' && <AdminHome />}
        {activeSection === 'addDish' && <AddDishForm menuId={menuId} />}
        {activeSection === 'viewMenu' && <ViewMenuItems menuId={menuId} />}
        {activeSection === 'manageServers' && <AddServerForm />}
        {activeSection === 'manageCooks' && <AddCookForm />}
        {activeSection === 'manageBarmaids' && <AddBarForm />}
        {activeSection === 'statistics' && <Statistics />}
        {activeSection === 'weekInvoices' && <WeekInvoices />}
        {activeSection === 'manageSchedule' && <ManageSchedule />}
        {activeSection === 'WorkHours' && <WorkHours />}
        {activeSection === 'myClient' && <ClientId />}
        {activeSection === 'avis' && <Avis />}
        {activeSection === 'myPaiement' && <MyPaiement />}
        {activeSection === 'comparaison' && <ComparaisonDate />}
        {activeSection === 'ManageTables' && <ManageTables />}
      </div>
      
      <div style={{ marginTop: '20px' }}>
        <h3>État de la page principale</h3>
        <button onClick={togglePageStatus} className='control-home-page'>
          {isPageActive ? 'Désactiver le menu digital' : 'Activer le menu digital'}
        </button>
      </div>

      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '10px 0',
          fontSize: '0.9em',
          color: '#555',
        }}
      >
        Cette application est développée par{' '}
        <a
          href="https://skaneat.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          SkanEat
        </a>
        .
      </div>
    </div>
  );
};

export default AdminDashboard;
