import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Formulaire from './components/menu/Formulaire';
import Menu from './components/menu/Menu';
import AuthLogin from './components/AuthLogin';
import AdminDashboard from './components/admin/AdminDashboard';
import ProtectedRoute from './components/menu/ProtectedRoute';
import ProtectedMenu from './components/menu/ProtectedMenu';
import ResumeCommande from './components/menu/ResumeCommande';
import Paiement from './components/menu/Paiement';
import ServerInterface from './components/server/ServerInterface';
import CookDashboard from './components/cook/CookDashboard';
import BarDashboard from './components/bar/BarDashboard';
import Confirmation from './components/menu/Confirmation';
import Satisfaction from './components/menu/Satisfaction';
import CashPaiement from './components/menu/CashPaiement';
import TakeAway from './components/menu/TakeAway';
import Tools from './Tools'; // A RETIRER

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Formulaire />} />
        <Route 
          path="/menu" 
          element={
            <ProtectedMenu>
              <Menu />
            </ProtectedMenu>
          } 
        />
        <Route path="/resume" element={<ResumeCommande />} /> {/* Ajoutez la route ici */}
        <Route path="/paiement" element={<Paiement />} /> {/* Ajoutez la route de paiement ici */}
        <Route path="/cashpaiement" element={<CashPaiement />} />
        <Route path="/login" element={<AuthLogin />} />
        <Route path="/takeaway" element={<TakeAway />} />
        <Route 
          path="/server-interface" 
          element={
            <ProtectedRoute requiredRole="server">
              <ServerInterface />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/cook-interface" 
          element={
            <ProtectedRoute requiredRole="cook">
              <CookDashboard />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/bar-interface" 
          element={
            <ProtectedRoute requiredRole="bar">
              <BarDashboard />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin-dashboard" 
          element={
            <ProtectedRoute requiredRole="admin">
              <AdminDashboard />
            </ProtectedRoute>
          } 
        />
        <Route path="/confirmation" element={<Confirmation />} />
        <Route path="/satisfaction" element={<Satisfaction />} />
        <Route path="/tools" element={<Tools />} /> {/* A RETIRER */}
      </Routes>
    </Router>
  );
};

export default App;

