import React, { useState } from 'react';
import AdminDeletePage from './tools(notinclued)/AdminDeletePage'; // Assurez-vous d'importer votre composant AdminDeletePage
import CreateAdmin from './tools(notinclued)/CreateAdmin'; // Assurez-vous d'importer votre composant CreateAdmin
import FirestoreViewer from './tools(notinclued)/FirestoreViewer'; // Assurez-vous d'importer votre composant FirestoreViewer
import CreateClient from './tools(notinclued)/CreateClient';

const Tools = () => {
  const [activeSection, setActiveSection] = useState('');

  return (
    <div>
      <h1>Tools Menu</h1>
      <div>
        <button onClick={() => setActiveSection('DeleteDB')}>Supprimer la BDD</button>
        <button onClick={() => setActiveSection('CreateAdmin')}>Créer un Admin</button>
        <button onClick={() => setActiveSection('FirestoreViewer')}>Voir la Base de Données</button>
        <button onClick={() => setActiveSection('CreateClient')}>Créer un client</button>
      </div>

      <div>
        {activeSection === 'DeleteDB' && <AdminDeletePage />}
        {activeSection === 'CreateAdmin' && <CreateAdmin />}
        {activeSection === 'FirestoreViewer' && <FirestoreViewer />}
        {activeSection === 'CreateClient' && <CreateClient />}
      </div>
    </div>
  );
};

export default Tools;
