import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import EmployeeCard from './EmployeeCard';
import SchedulePopup from './SchedulePopup';
import './ManageSchedule.css';

const ManageSchedule = () => {
  const [employees, setEmployees] = useState([]);
  const [schedules, setSchedules] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [todaysWorkers, setTodaysWorkers] = useState([]);
  const [employeeColors, setEmployeeColors] = useState({});
  const [hiddenEmployees, setHiddenEmployees] = useState(new Set());
  const [selectedDay, setSelectedDay] = useState(null);
  const [hiddenRoles, setHiddenRoles] = useState(new Set());

  // Récupérer les employés (tous sauf ceux ayant le rôle "admin")
  useEffect(() => {
    const fetchEmployees = async () => {
      const employeesCollection = collection(db, 'identifications');
      const q = query(employeesCollection, where('role', '!=', 'admin'));
      const snapshot = await getDocs(q);
      const employeesList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEmployees(employeesList);

      // Assignation d'une couleur unique à chaque employé
      const colors = employeesList.reduce((acc, employee, index) => {
        acc[employee.id] = generateUniqueColor(index);
        return acc;
      }, {});
      setEmployeeColors(colors);
    };

    fetchEmployees();
  }, []);

  // Générer une couleur unique (par exemple, à partir d'un index)
  const generateUniqueColor = (index) => {
    const colors = [
      '#FF6347', '#4682B4', '#32CD32', '#FFD700', '#8A2BE2', '#DC143C',
      '#A52A2A', '#5F9EA0', '#D2691E', '#FF4500', '#00BFFF', '#FF1493', '#00FA9A',
      '#F4A300', '#3CB371', '#7B68EE'
    ];
    return colors[index % colors.length]; // Retourner une couleur unique par employé
  };

  // Récupérer les horaires des employés
  useEffect(() => {
    const fetchSchedules = async () => {
      const schedulesCollection = collection(db, 'horaires');
      const snapshot = await getDocs(schedulesCollection);
      const schedulesList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      const schedulesData = schedulesList.reduce((acc, schedule) => {
        acc[schedule.employeeId] = schedule;
        return acc;
      }, {});

      setSchedules(schedulesData);
    };

    fetchSchedules();
  }, []);

  // Fonction pour obtenir les employés qui travaillent aujourd'hui
  useEffect(() => {
    const today = new Date();
    const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const todayDayName = dayNames[today.getDay() - 1]; // "getDay()" retourne un nombre de 0 (Dimanche) à 6 (Samedi)

    const workersToday = employees.filter(employee => {
      const schedule = schedules[employee.id];
      return schedule && schedule.schedule[todayDayName] && schedule.schedule[todayDayName] !== 'Ne travaille pas';
    });

    setTodaysWorkers(workersToday);
  }, [employees, schedules]);

  const handleEmployeeClick = (employee, day) => {
    setSelectedEmployee(employee);
    setSelectedDay(day);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedEmployee(null);
  };

  // Mettre à jour l'état des horaires après modification
  const updateSchedule = (employeeId, newSchedule) => {
    setSchedules(prevSchedules => ({
      ...prevSchedules,
      [employeeId]: { ...newSchedule },
    }));
  };

  // Fonction pour afficher le planning sous forme de tableau
  const renderScheduleGrid = () => {
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    // Créer des tranches de 30 minutes de 10h à 22h30
    const timeSlots = Array.from({ length: 26 }, (_, i) => {
      const hour = Math.floor(i / 2) + 10;
      const minutes = i % 2 === 0 ? '00' : '30';
      return `${hour}:${minutes}`;
    });

    // Modifier la structure pour stocker les employés par tranche de 30 minutes
    const scheduleGrid = daysOfWeek.reduce((acc, day) => {
      acc[day] = timeSlots.map(timeSlot => {
        const [hour, minutes] = timeSlot.split(':').map(Number);
        // Filtrer les employés qui travaillent pendant cette tranche
        const workersForSlot = employees
          .filter(employee => !hiddenEmployees.has(employee.id))
          .filter(employee => !hiddenRoles.has(employee.role))
          .filter(employee => {
            const schedule = schedules[employee.id];
            const workTime = schedule?.schedule[day] || '';
            return isWorking(workTime, hour, minutes);
          });
        return workersForSlot;
      });
      return acc;
    }, {});

    return (
      <div className="schedule-table">
        <div className="schedule-header">
          <div className="empty-cell"></div>
          {timeSlots.map(timeSlot => (
            <div key={timeSlot} className="hour-cell">{timeSlot}</div>
          ))}
        </div>

        {/* Le reste du code du tableau reste similaire, mais utilise timeSlots au lieu de hoursOfDay */}
        {daysOfWeek.map((day) => (
          <div key={day} className="day-row">
            <div className="day-cell">{day}</div>
            {timeSlots.map((timeSlot, slotIndex) => (
              <div 
                key={slotIndex} 
                className="time-cell"
                onClick={() => handleEmployeeClick(employees[0], day)}
              >
                {scheduleGrid[day][slotIndex].map((employee, employeeIndex) => {
                  const color = employeeColors[employee.id];
                  return (
                    <div
                      key={employee.id}
                      className="employee-block"
                      style={{ backgroundColor: color, top: `${employeeIndex * 20}px` }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEmployeeClick(employee, day);
                      }}
                    >
                      {employee.firstName}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };

  const handleRoleToggle = (role) => {
    const newHiddenRoles = new Set(hiddenRoles);
    
    if (hiddenRoles.has(role)) {
      // Si on réactive le rôle
      newHiddenRoles.delete(role);
      // Réactiver tous les employés de ce rôle qui étaient cachés à cause du rôle
      const newHiddenEmployees = new Set(hiddenEmployees);
      employees.forEach(employee => {
        if (employee.role === role) {
          newHiddenEmployees.delete(employee.id);
        }
      });
      setHiddenEmployees(newHiddenEmployees);
    } else {
      // Si on désactive le rôle
      newHiddenRoles.add(role);
      // Désactiver tous les employés de ce rôle
      const newHiddenEmployees = new Set(hiddenEmployees);
      employees.forEach(employee => {
        if (employee.role === role) {
          newHiddenEmployees.add(employee.id);
        }
      });
      setHiddenEmployees(newHiddenEmployees);
    }
    
    setHiddenRoles(newHiddenRoles);
  };

  const handleEmployeeToggle = (employeeId) => {
    const newHiddenEmployees = new Set(hiddenEmployees);
    const employee = employees.find(emp => emp.id === employeeId);
    
    if (hiddenEmployees.has(employeeId)) {
      // Réactiver l'employé
      newHiddenEmployees.delete(employeeId);
      
      // Vérifier si on doit réactiver le rôle
      if (hiddenRoles.has(employee.role)) {
        const roleEmployees = employees.filter(emp => emp.role === employee.role);
        const otherHiddenEmployeesCount = roleEmployees.filter(emp => 
          emp.id !== employeeId && newHiddenEmployees.has(emp.id)
        ).length;
        
        // Si tous les autres employés du rôle sont visibles, réactiver le rôle
        if (otherHiddenEmployeesCount === 0) {
          setHiddenRoles(prev => {
            const newHiddenRoles = new Set(prev);
            newHiddenRoles.delete(employee.role);
            return newHiddenRoles;
          });
        }
      }
    } else {
      // Désactiver l'employé
      newHiddenEmployees.add(employeeId);
      
      // Vérifier si tous les employés du même rôle sont désactivés
      const roleEmployees = employees.filter(emp => emp.role === employee.role);
      const allRoleEmployeesHidden = roleEmployees.every(emp => 
        emp.id === employeeId || newHiddenEmployees.has(emp.id)
      );
      
      // Si tous les employés du rôle sont désactivés, désactiver le rôle
      if (allRoleEmployeesHidden) {
        setHiddenRoles(prev => {
          const newHiddenRoles = new Set(prev);
          newHiddenRoles.add(employee.role);
          return newHiddenRoles;
        });
      }
    }
    
    setHiddenEmployees(newHiddenEmployees);
  };

  // Légende des couleurs
  const renderLegend = () => {
    const uniqueRoles = [...new Set(employees.map(emp => emp.role))];
    
    return (
      <div className="legend">
        <h4>Légende</h4>
        <div className="legend-section">
          <h5>Employés :</h5>
          <div className="legend-items-container">
            {employees.map(employee => {
              const isRoleHidden = hiddenRoles.has(employee.role);
              const isEmployeeHidden = hiddenEmployees.has(employee.id);
              
              return (
                <div
                  key={employee.id}
                  className={`legend-item ${(isRoleHidden || isEmployeeHidden) ? 'disabled' : ''}`}
                  onClick={() => handleEmployeeToggle(employee.id)}
                >
                  <div 
                    className="legend-color" 
                    style={{ backgroundColor: employeeColors[employee.id] }}
                  ></div>
                  <span>{employee.firstName} {employee.lastName}</span>
                </div>
              );
            })}
          </div>
        </div>
        
        <div className="legend-section">
          <h5>Rôles :</h5>
          <div className="legend-items-container">
            {uniqueRoles.map(role => (
              <div
                key={role}
                className={`legend-item ${hiddenRoles.has(role) ? 'disabled' : ''}`}
                onClick={() => handleRoleToggle(role)}
              >
                <div 
                  className="legend-role-indicator"
                  style={{ backgroundColor: employeeColors[role] }}
                ></div>
                <span>{getRoleInFrench(role)}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const isWorking = (workTime, hour, minutes) => {
    if (!workTime || workTime === 'Ne travaille pas') {
      return false;
    }

    return workTime.split(' / ').some(period => {
      const [start, end] = period.split(' - ').map(time => {
        const [h, m] = time.split(':').map(Number);
        return h * 60 + (m || 0); // Convertir en minutes
      });
      const currentTime = hour * 60 + minutes;
      return start <= currentTime && currentTime < end;
    });
  };

  const renderTodaysWorkers = () => {
    const today = new Date();
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const todayDayName = dayNames[today.getDay()];
    
    // Groupe les workers par rangées de 3
    const rows = [];
    for (let i = 0; i < todaysWorkers.length; i += 3) {
      rows.push(todaysWorkers.slice(i, i + 3));
    }

    return (
      <div className="todays-workers">
        <h3>Personnel travaillant aujourd'hui ({new Date().toLocaleDateString('fr-FR', { weekday: 'long' })})</h3>
        <div className="workers-grid">
          {rows.map((row, rowIndex) => (
            <div key={`row-${rowIndex}`} className="row-container">
              {row.map((worker) => (
                <div key={worker.id} className="worker-card">
                  <div className="worker-name">{worker.firstName} {worker.lastName}</div>
                  <div className="worker-role">{getRoleInFrench(worker.role)}</div>
                  <div className="worker-schedule">
                    {schedules[worker.id]?.schedule[todayDayName] === 'Ne travaille pas' 
                      ? 'Repos' 
                      : schedules[worker.id]?.schedule[todayDayName]}
                  </div>
                </div>
              ))}
            </div>
          ))}
          {todaysWorkers.length === 0 && (
            <div className="worker-card">
              <div className="worker-name">Aucun personnel prévu aujourd'hui</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getRoleInFrench = (role) => {
    const roles = {
      server: 'Serveur',
      cook: 'Cuisinier',
      bar: 'Barman',
      admin: 'Administrateur'
    };
    return roles[role] || role;
  };

  return (
    <div className="manage-schedule">
      <h2>Gestion du planning</h2>
      <div className="employee-cards">
        {employees.map(employee => (
          <EmployeeCard
            key={employee.id}
            employee={employee}
            onClick={(employee, day) => handleEmployeeClick(employee, day)}
            schedule={schedules[employee.id]}
          />
        ))}
      </div>
      {renderTodaysWorkers()}

      {/* Affichage du planning graphique */}
      {renderScheduleGrid()}

      {/* Affichage de la légende des couleurs */}
      {renderLegend()}

      {showPopup && (
        <div>
          <SchedulePopup
            employee={selectedEmployee}
            onClose={handleClosePopup}
            existingSchedule={schedules[selectedEmployee.id]}
            updateSchedule={updateSchedule}
            selectedDay={selectedDay}
          />
        </div>
      )}
    </div>
  );
};

export default ManageSchedule;
