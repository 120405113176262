import React, { useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './Satisfaction.css';

const Satisfaction = ({ setShowSatisfaction }) => {
  const [ratings, setRatings] = useState({
    plats: 0,
    service: 0,
    application: 0,
  });
  const [feedback, setFeedback] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // État pour le message d'erreur

  const handleRatingChange = (category, value) => {
    setRatings({ ...ratings, [category]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (ratings.plats === 0 || ratings.service === 0 || ratings.application === 0) {
      setErrorMessage('Veuillez attribuer une note pour chaque catégorie.');
      return;
    }
  
    try {
      await addDoc(collection(db, 'satisfaction'), {
        ...ratings,
        feedback,
        date: new Date(),
      });
      setSuccessMessage(true);
      setErrorMessage('');
      setTimeout(() => {
        setSuccessMessage(false);
        setShowSatisfaction(false); // Fermer la popup automatiquement après la soumission
      }, 500);
    } catch (error) {
      console.error('Erreur lors de l\'ajout des données de satisfaction:', error);
    }
  };

  return (
    <div className="satisfaction-container">
      <h2>Merci d'avoir commandé chez "Notre Resto"</h2>
      <p>Nous aimerions connaître votre avis pour améliorer notre service.</p>
      <form onSubmit={handleSubmit} className="satisfaction-form">
        <div className="rating-group">
          <label>Plats :</label>
          <div className="stars">
            {[1, 2, 3, 4, 5].map((value) => (
              <span
                key={value}
                className={`star ${ratings.plats >= value ? 'selected' : ''}`}
                onClick={() => handleRatingChange('plats', value)}
              >
                ★
              </span>
            ))}
          </div>
        </div>
        <div className="rating-group">
          <label>Service :</label>
          <div className="stars">
            {[1, 2, 3, 4, 5].map((value) => (
              <span
                key={value}
                className={`star ${ratings.service >= value ? 'selected' : ''}`}
                onClick={() => handleRatingChange('service', value)}
              >
                ★
              </span>
            ))}
          </div>
        </div>
        <div className="rating-group">
          <label>Application :</label>
          <div className="stars">
            {[1, 2, 3, 4, 5].map((value) => (
              <span
                key={value}
                className={`star ${ratings.application >= value ? 'selected' : ''}`}
                onClick={() => handleRatingChange('application', value)}
              >
                ★
              </span>
            ))}
          </div>
        </div>
        <div className="feedback-group">
          <label>Commentaires :</label>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Partagez vos suggestions ou remarques ici..."
          />
        </div>
        <button type="submit" className="satisfaction-submit-btn">Envoyer</button>
      </form>

      {/* Afficher un message d'erreur si une note est manquante */}
      {errorMessage && <p className="satisfaction-error-message">{errorMessage}</p>}

      {successMessage && <p className="satisfaction-success-message">Merci pour vos retours !</p>}
    </div>
  );
};

export default Satisfaction;
