import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, query, where, onSnapshot, doc, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import './MyPaiement.css';

const MyPaiement = () => {
  const [clients, setClients] = useState([]); // Liste des clients avec paiement (tous les statuts)
  const [loading, setLoading] = useState(true); // Indicateur de chargement

  useEffect(() => {
    // Récupérer les clients avec paiement (tous les statuts)
    const fetchClients = async () => {
      try {
        const clientsCollection = collection(db, 'clients');
        const q = query(clientsCollection, where('paiement', '!=', null));  // Récupérer tous les clients ayant un objet paiement

        onSnapshot(q, async (querySnapshot) => {
          const clientsData = await Promise.all(
            querySnapshot.docs.map(async (docSnapshot) => {
              const clientData = docSnapshot.data();
              const clientId = docSnapshot.id;
        
              // Récupérer les détails de la commande pour ce client
              const commandesCollection = collection(db, `clients/${clientId}/commande`);
              const commandesSnapshot = await getDocs(commandesCollection);
              let totalCommande = 0;
              let items = [];
        
              commandesSnapshot.forEach((doc) => {
                const commandeData = doc.data();
                items = [...items, ...commandeData.items];
                totalCommande += commandeData.items.reduce((acc, item) => acc + item.prix * item.quantity, 0);
              });

              const tableLabel = clientData.table === 'a_emporter' ? 'À Emporter' : `Numéro de Table ${clientData.table}`;
        
              return {
                id: clientId,
                prenom: clientData.prenom,
                table: tableLabel,
                email: clientData.email,
                idclient: clientData.idclient,
                paiement: clientData.paiement,
                items,
                totalCommande,
              };
            })
          );
          setClients(clientsData);
          setLoading(false);
        });        
      } catch (error) {
        console.error('Erreur lors de la récupération des clients:', error);
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  // Fonction pour mettre à jour le statut du paiement
  const handlePaiementEffectue = async (clientId) => {
    try {
      const clientRef = doc(db, 'clients', clientId);
      await updateDoc(clientRef, {
        'paiement.status': 'payé',
      });
      alert('Paiement marqué comme effectué');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du paiement:', error);
    }
  };

  // Fonction pour imprimer le ticket
  const handleImprimerTicket = (client) => {
    const ticketContent = `
      Résumé de commande :
      --------------------
      Client: ${client.prenom}
      Numéro de Table: ${client.table}
      Numéro de Commande: ${client.idclient}
      Email: ${client.email}
      
      Détails de la commande :
      --------------------
      ${client.items && client.items.length > 0 ? client.items.map((item, index) => `
        ${item.nom} - Quantité: ${item.quantity} - Prix Unitaire: ${item.prix.toFixed(2)} €
        Prix Total: ${(item.prix * item.quantity).toFixed(2)} €
      `).join('\n') : 'Aucun article dans la commande.'}
      
      Total de la commande: ${client.totalCommande.toFixed(2)} €
      
      Merci de votre commande !`;
    
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Ticket de caisse</title></head><body>');
    printWindow.document.write(`<pre>${ticketContent}</pre>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  if (loading) {
    return <div>Chargement des clients...</div>;
  }

  const handleDeleteCommande = async (clientId) => {
    try {
      const clientRef = doc(db, 'clients', clientId);
      await deleteDoc(clientRef);
      alert('Commande supprimée de la base de données.');
    } catch (error) {
      console.error('Erreur lors de la suppression de la commande:', error);
    }
  };  

  return (
    <div className="mypaiement">
      <h1 className='mypaiement-title'>Liste des Paiements</h1>
      <div className="mypaiement-container">
        {clients.length > 0 ? (
          clients.map((client) => (
            <div key={client.id} className="client-card">
              <h2>Client : {client.prenom}</h2>
              <p className='paiement-table'>Table : {client.table}</p>
              <p>Numéro de commande : {client.idclient}</p>
              <p>Email : {client.email}</p>
              <p>Status de paiement : {client.paiement.status}</p>
              
              <h3 className='details-card-command'>Détails de la commande :</h3>
              {client.items && client.items.length > 0 ? (
                <ul>
                  {client.items.map((item, index) => (
                    <li key={index}>
                      <strong>{item.nom}</strong> <br/>
                      Quantité : {item.quantity} <br/>
                      Prix Unitaire: {item.prix.toFixed(2)} € <br/>
                      Prix Total: {(item.prix * item.quantity).toFixed(2)} €
                    </li>
                  ))}
                </ul>
              ) : (
                <p>Aucun article dans la commande.</p>
              )}
              
              <h3 className='total-card-command'>Total de la commande: {client.totalCommande.toFixed(2)} €</h3>

              <div className='accept-button'>
                {client.paiement.status === 'en_attente' && (
                  <button onClick={() => handlePaiementEffectue(client.id)}>Marquer comme payé</button>
                )}
                {client.paiement.status === 'payé' && (
                <button className='delete-button-client' onClick={() => handleDeleteCommande(client.id)}>Supprimer la commande</button>
                )}
                <button onClick={() => handleImprimerTicket(client)}>Imprimer le ticket</button>
              </div>
            </div>
          ))
        ) : (
          <p>Aucune commande trouvée.</p>
        )}
      </div>
    </div>
  );
};

export default MyPaiement;
