import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, query, onSnapshot, doc, where } from 'firebase/firestore';
import Modal from 'react-modal';
import './GlobalView.css';
import TableSVG from '../../assets/table.svg';

const GlobalView = () => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableOrders, setTableOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableLimit, setTableLimit] = useState(20);

  useEffect(() => {
    const unsubscribeSettings = onSnapshot(doc(db, 'settings', 'nb_table'), (docSnap) => {
      if (docSnap.exists()) {
        const limit = docSnap.data().value;
        setTableLimit(limit || 20);
      } else {
        console.error('Le document "nb_table" n\'existe pas dans la collection settings.');
      }
    });

    const clientsCollection = collection(db, 'clients');
    const q = query(clientsCollection);

    const unsubscribeClients = onSnapshot(q, (snapshot) => {
      const occupiedTables = new Set();
      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.table) {
          occupiedTables.add(data.table);
        }
      });

      const initialTables = Array.from({ length: tableLimit }, (_, i) => ({
        id: i + 1,
        occupied: occupiedTables.has(i + 1),
      }));

      setTables(initialTables);
    });

    return () => {
      unsubscribeSettings();
      unsubscribeClients();
    };
  }, [tableLimit]);

  const handleTableClick = (table) => {
    setSelectedTable(table.id);
    fetchTableOrders(table.id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setTableOrders([]);
  };

  const fetchTableOrders = async (tableNumber) => {
    try {
      const clientsCollection = collection(db, 'clients');
      const q = query(clientsCollection, where('table', '==', tableNumber));

      onSnapshot(q, (snapshot) => {
        if (!snapshot.empty) {
          const commandes = [];
          snapshot.forEach((clientDoc) => {
            const commandesCollection = collection(db, `clients/${clientDoc.id}/commande`);
            onSnapshot(commandesCollection, (commandesSnapshot) => {
              commandesSnapshot.forEach((commandeDoc) => {
                const commandeData = commandeDoc.data();
                commandes.push({
                  id: commandeDoc.id,
                  ...commandeData,
                  serverName: commandeData.serverName || 'Inconnu',
                });
              });
              setTableOrders(commandes);
            });
          });
        } else {
          setTableOrders([]);
        }
      });
    } catch (error) {
      console.error('Erreur lors de la récupération des commandes :', error);
    }
  };

  const calculateTotal = () => {
    return tableOrders.reduce((total, commande) => {
      const commandeTotal = commande.items.reduce(
        (sum, item) => sum + item.prix * item.quantity,
        0
      );
      return total + commandeTotal;
    }, 0);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Initialisée':
        return 'status-label initialized';
      case 'Accepté':
        return 'status-label accepted';
      case 'Terminé':
        return 'status-label completed';
      case 'Commande servi':
        return 'status-label served';
      default:
        return 'status-label default';
    }
  };

  return (
    <div className="global-view">
      <h1>Vue Globale des Tables</h1>
      <div className="tables-grid">
        {tables.map((table) => (
          <div
            key={table.id}
            className={`table-container ${table.occupied ? 'occupied' : ''}`}
            onClick={() => handleTableClick(table)}
          >
            <img src={TableSVG} alt={`Table ${table.id}`} className="table-svg" />
            <span className="table-number">Table {table.id}</span>
          </div>
        ))}
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Commandes de la Table"
        className="modal"
        overlayClassName="overlay"
      >
        <h2 className="table-orders-title">Commandes pour la Table {selectedTable}</h2>
        {tableOrders.length > 0 ? (
          <div className="table-orders-container">
            {tableOrders.map((commande, index) => (
              <div key={commande.id} className="commande-details">
                <div className="commande-items">
                  {commande.items.map((item, itemIndex) => (
                    <p key={itemIndex} className="commande-item">
                      <strong>{item.nom}</strong> - <span className="quantity">Quantité :</span> {item.quantity} <br />
                      <span className="unit-price">Prix unitaire :</span> {item.prix} €
                      {item.cuisson && <span className="cuisson"> Cuisson : {item.cuisson}</span>}
                    </p>
                  ))}
                </div>
                <h4 className="commande-total">
                  Total : <span>{commande.items.reduce((acc, item) => acc + item.prix * item.quantity, 0).toFixed(2)} €</span>
                </h4>
                <p className="commande-status">
                  <span className={getStatusStyle(commande.statut)}>{commande.statut}</span>
                </p>
                {index < tableOrders.length - 1 && <hr className="commande-separator" />}
                <h3 className="commande-server">
                  Commande gérée par : <span>{commande.serverName || 'Non assigné'}</span>
                </h3>
              </div>
            ))}
            <h3 className="total-table">
              Total pour la table : <span>{calculateTotal().toFixed(2)} €</span>
            </h3>
          </div>
        ) : (
          <p className="no-result-table">Aucune commande trouvée pour cette table.</p>
        )}
        <button onClick={closeModal} className="close-button">
          Fermer
        </button>
      </Modal>
    </div>
  );
};

export default GlobalView;
