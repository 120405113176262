import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, doc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './Formulaire.css';

const TakeAway = () => {
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [erreur, setErreur] = useState('');
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [accessError] = useState('');
  const [isPageActive, setIsPageActive] = useState(true);
  const [loading, setLoading] = useState(true);

  const generateRandomId = () => {
    return Math.floor(1000 + Math.random() * 9000); // Génère un nombre entre 1000 et 9999
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!prenom || !email) {
      setErreur('Tous les champs sont requis.');
      return;
    }

    setErreur('');

    try {
      const clientId = generateRandomId(); // Génère un ID à 4 chiffres
      const docRef = await addDoc(collection(db, 'clients'), {
        prenom: prenom,
        table: 'a_emporter', // Remplace "table" par "a_emporter"
        email: email,
        idclient: clientId, // Ajouter l'idclient dans Firestore
      });

      // Stocker les informations utilisateur dans le localStorage
      localStorage.setItem(
        'userInfo',
        JSON.stringify({
          prenom,
          table: 'a_emporter', // Enregistrer également "a_emporter" dans localStorage
          email,
          id: docRef.id, // ID Firebase
          idclient: clientId, // Ajout de l'idclient ici
        })
      );

      console.log('Document écrit avec ID: ', docRef.id);
      setSuccess(true);
      navigate('/menu');
    } catch (error) {
      console.error('Erreur lors de l\'ajout du document: ', error);
      setErreur('Erreur lors de l\'ajout du document.');
    }
  };

    useEffect(() => {
        const docRef = doc(db, 'settings', 'pageStatus');
        const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
            setIsPageActive(docSnap.data().isActive);
        } else {
            console.error('Le document "pageStatus" n\'existe pas.');
        }
        setLoading(false);
        }, (error) => {
        console.error('Erreur lors de l\'écoute des modifications :', error);
        setLoading(false);
        });
    
        // Nettoyage de l'écouteur lors du démontage du composant
        return () => unsubscribe();
    }, []);  
  
    if (loading) return <p>Chargement...</p>;
  
    if (!isPageActive) {
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <h1>Page Désactivée</h1>
          <p>Cette page est actuellement désactivée. Revenez plus tard.</p>
        </div>
      );
    }

  return (
    <div className="client-form-container">
      {success ? (
        <h2 className="client-success-message">Maintenant, c'est le temps de commander</h2>
      ) : (
        <>
          <h2 className="client-form-title">Vos informations</h2>
          {accessError && <p className="client-access-error">{accessError}</p>}
          <form onSubmit={handleSubmit} className="client-form">
            <div className="client-input-group">
              <p className='your_commande'>Commande À Emporter</p>
              <label>Prénom :</label>
              <input
                type="text"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
                required
              />
            </div>
            <div className="client-input-group">
              <label>Email :</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            {erreur && <p className="client-error-message">{erreur}</p>}
            <button type="submit" className="client-submit-btn">Continuer</button>
          </form>
        </>
      )}
      <div
        style={{
          textAlign: 'center',
          marginTop: '20px',
          padding: '10px 0',
          fontSize: '0.9em',
          color: '#555',
        }}
      >
        Cette application est développée par{' '}
        <a
          href="https://skaneat.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: '#007BFF',
            textDecoration: 'none',
            fontWeight: 'bold',
          }}
        >
          SkanEat
        </a>
        .
      </div>
    </div>
  );
};

export default TakeAway;
